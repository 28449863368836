export interface IMetaTags {
    title?: string;
    url?: string;
    description?: string;
    image?: string;
    siteName?: string;
    fbAppId?: string;
    websiteType?: string;
    keywords?: string;
    author?: string;
    facebookDomainVerification?: string;
    appleItunesApp?: string;
}

export interface IStaticMetaContent {
    [key: string]: IMetaTags;
}

const description =
    'Takeaway ви овозможува да јадете токму тоа што ви се јаде, кога вие ќе посаката и каде што ќе посакате. Нарачајте ги вашите омилени јадења, од омилените ресторани со само неколку кликнувања, опуштете се и ние ке ви го донесеме јадењето до дома, канцеларија и каде и да посакате вие, во цело Скопје.';
const image = '/favicon.svg';
export const siteName = 'Takeaway';
export const fbAppId = '1039582786888795';
export const websiteType = 'website';
export const keywords =
    'достава на храна, достава до дома, достава, испорака на храна, испорака до дома, испорака, нарачај, нарачај преку интернет, ресторан, кликни јади, кинеска, мексиканска, кубанска, специјалитети, готвени јадења, здрава храна, вегетаријанска, веганска, макробиотика, салати, пица, сендвич, food delivery, order food, order food online, restaurant, click and eat, chinese food, mexican food, cuban food, food specialties, cooked food, meals, healthy food, vegetarian food, vegan food, macrobiotic food, pizza, salads, sandwich';
export const author = 'Takeaway';
export const facebookDomainVerification = '';
export const appleItunesApp = '';

export const staticMetaContent: IStaticMetaContent = {
    listings: {
        title: 'Listings',
        url: `${process.env.NEXT_PUBLIC_DOMAIN_URL}/listings`,
        image: image,
        description: description,
        siteName: siteName,
        fbAppId: fbAppId,
        websiteType: websiteType,
        keywords: keywords,
        author: author,
        facebookDomainVerification: facebookDomainVerification,
        appleItunesApp: appleItunesApp,
    },
    homepage: {
        title: 'Takeaway',
        url: `${process.env.NEXT_PUBLIC_DOMAIN_URL}`,
        image: image,
        description: description,
        siteName: siteName,
        fbAppId: fbAppId,
        websiteType: websiteType,
        keywords: keywords,
        author: author,
        facebookDomainVerification: facebookDomainVerification,
        appleItunesApp: appleItunesApp,
    },
    cart: {
        title: 'Cart',
        url: `${process.env.NEXT_PUBLIC_DOMAIN_URL}/cart`,
        image: image,
        description: description,
        siteName: siteName,
        fbAppId: fbAppId,
        websiteType: websiteType,
        keywords: keywords,
        author: author,
        facebookDomainVerification: facebookDomainVerification,
        appleItunesApp: appleItunesApp,
    },
    togetherCompanies: {
        title: 'Together - Companies',
        url: `${process.env.NEXT_PUBLIC_DOMAIN_URL}/cart`,
        image: image,
        description: description,
        siteName: siteName,
        fbAppId: fbAppId,
        websiteType: websiteType,
        keywords: keywords,
        author: author,
        facebookDomainVerification: facebookDomainVerification,
        appleItunesApp: appleItunesApp,
    },
    profileWallet: {
        title: 'Profile - Wallet',
        url: `${process.env.NEXT_PUBLIC_DOMAIN_URL}/profile/wallet`,
        image: image,
        description: description,
        siteName: siteName,
        fbAppId: fbAppId,
        websiteType: websiteType,
        keywords: keywords,
        author: author,
        facebookDomainVerification: facebookDomainVerification,
        appleItunesApp: appleItunesApp,
    },
    profileAllergies: {
        title: `Profile - Food you don't like`,
        url: `${process.env.NEXT_PUBLIC_DOMAIN_URL}/profile/foods`,
        image: image,
        description: description,
        siteName: siteName,
        fbAppId: fbAppId,
        websiteType: websiteType,
        keywords: keywords,
        author: author,
        facebookDomainVerification: facebookDomainVerification,
        appleItunesApp: appleItunesApp,
    },
    profileReservations: {
        title: 'Profile - Reservations',
        url: `${process.env.NEXT_PUBLIC_DOMAIN_URL}/profile/reservations`,
        image: image,
        description: description,
        siteName: siteName,
        fbAppId: fbAppId,
        websiteType: websiteType,
        keywords: keywords,
        author: author,
        facebookDomainVerification: facebookDomainVerification,
        appleItunesApp: appleItunesApp,
    },
    profileOrders: {
        title: 'Profile - Orders',
        url: `${process.env.NEXT_PUBLIC_DOMAIN_URL}/profile/orders`,
        image: image,
        description: description,
        siteName: siteName,
        fbAppId: fbAppId,
        websiteType: websiteType,
        keywords: keywords,
        author: author,
        facebookDomainVerification: facebookDomainVerification,
        appleItunesApp: appleItunesApp,
    },
    profileNotifications: {
        title: 'Profile - Notifications',
        url: `${process.env.NEXT_PUBLIC_DOMAIN_URL}/profile/notifications`,
        image: image,
        description: description,
        siteName: siteName,
        fbAppId: fbAppId,
        websiteType: websiteType,
        keywords: keywords,
        author: author,
        facebookDomainVerification: facebookDomainVerification,
        appleItunesApp: appleItunesApp,
    },
    profileManage: {
        title: 'Profile - Manage',
        url: `${process.env.NEXT_PUBLIC_DOMAIN_URL}/profile/manage`,
        image: image,
        description: description,
        siteName: siteName,
        fbAppId: fbAppId,
        websiteType: websiteType,
        keywords: keywords,
        author: author,
        facebookDomainVerification: facebookDomainVerification,
        appleItunesApp: appleItunesApp,
    },
    profileFavorites: {
        title: 'Profile - Favorites',
        url: `${process.env.NEXT_PUBLIC_DOMAIN_URL}/profile/favorites`,
        image: image,
        description: description,
        siteName: siteName,
        fbAppId: fbAppId,
        websiteType: websiteType,
        keywords: keywords,
        author: author,
        facebookDomainVerification: facebookDomainVerification,
        appleItunesApp: appleItunesApp,
    },
    exploreHowToOrder: {
        title: 'Profile - How to order',
        url: `${process.env.NEXT_PUBLIC_DOMAIN_URL}/explore/how-to-order`,
        image: image,
        description: description,
        siteName: siteName,
        fbAppId: fbAppId,
        websiteType: websiteType,
        keywords: keywords,
        author: author,
        facebookDomainVerification: facebookDomainVerification,
        appleItunesApp: appleItunesApp,
    },
    exploreContact: {
        title: 'Profile - Contact',
        url: `${process.env.NEXT_PUBLIC_DOMAIN_URL}/explore/contact`,
        image: image,
        description: description,
        siteName: siteName,
        fbAppId: fbAppId,
        websiteType: websiteType,
        keywords: keywords,
        author: author,
        facebookDomainVerification: facebookDomainVerification,
        appleItunesApp: appleItunesApp,
    },
    companyAboutUs: {
        title: 'Company - About us',
        url: `${process.env.NEXT_PUBLIC_DOMAIN_URL}/company/about-us`,
        image: image,
        description: description,
        siteName: siteName,
        fbAppId: fbAppId,
        websiteType: websiteType,
        keywords: keywords,
        author: author,
        facebookDomainVerification: facebookDomainVerification,
        appleItunesApp: appleItunesApp,
    },
    companyOurTeam: {
        title: 'Company - Our team',
        url: `${process.env.NEXT_PUBLIC_DOMAIN_URL}/company/our-team`,
        image: image,
        description: description,
        siteName: siteName,
        fbAppId: fbAppId,
        websiteType: websiteType,
        keywords: keywords,
        author: author,
        facebookDomainVerification: facebookDomainVerification,
        appleItunesApp: appleItunesApp,
    },
};
