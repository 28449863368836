import { useMemo } from 'react';
import { Box, BoxProps } from '@mui/material';

interface IResponsiveImageProps extends BoxProps {
    src: string;
    options?: ResponsiveOptions;
    crop?: boolean;
    alt?: string;
}

export interface ResponsiveOptions {
    width?: number;
    height?: number;
    format?: string;
}

const ResponsiveImage = ({ src, crop = false, options, ...rest }: IResponsiveImageProps) => {
    const source = useMemo(() => getResponsiveImageUrl(src, options, crop), [src, options]);

    return (
        <picture style={{ height: rest.style?.height || 'auto' }}>
            <source srcSet={attachImageExtension(source, 'rw')} type="image/webp" style={rest.style} className={rest.className} />
            <source srcSet={attachImageExtension(source, 'rj')} type="image/jpeg" style={rest.style} className={rest.className} />
            <Box component="img" src={attachImageExtension(source, 'rj')} {...rest} height="100%" />
        </picture>
    );
};

export const attachImageExtension = (src: string, extension: string) => {
    if (!src) return undefined;
    if (!isResponsiveImageUrl(src)) return src;
    if (src.endsWith('=')) return `${src}${extension}`;
    else return `${src}-${extension}`;
};

export const getResponsiveImageUrl = (src: string, options: ResponsiveOptions, crop: boolean = false) => {
    if (!src) return undefined;
    if (isResponsiveImageUrl(src)) {
        const optionsArray = [];

        if (options.width) optionsArray.push(`w${options.width}`);
        if (options.height) optionsArray.push(`h${options.height}`);
        if (crop) optionsArray.push('c');
        if (options.format) optionsArray.push(options.format);

        return `${src.replace('http://', 'https://')}=${optionsArray.join('-')}`;
    } else return src;
};

export const isResponsiveImageUrl = (src: string) => src.includes('lh3.googleusercontent.com/');

export default ResponsiveImage;
