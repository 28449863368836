import { Box, Paper } from '@mui/material';
import { MediumText, NormalText } from 'components/atoms/Typography';
import shoppingBag from 'assets/svg/shopping-bag.svg';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import useTranslation from 'next-translate/useTranslation';
import Link from 'next/link';
import { keys, lowerCase } from 'lodash';

interface ICartMobileProps {
    routerQuery?: any;
}

export default function CartMobile({ routerQuery }: ICartMobileProps) {
    const userCart = useSelector((state: RootState) => state.userCart);
    const { t } = useTranslation('common');

    if (userCart.data.isEmpty) return null;

    if (!keys(userCart.data.groupedItems).includes(routerQuery?.id)) return null;

    return (
        <Box width="100%" display="flex" flexDirection="column" p={3}>
            <Paper
                sx={{
                    backgroundColor: 'secondary.main',
                    px: 3,
                    py: 2,
                    borderRadius: 2,
                }}
                elevation={5}
            >
                <Link href="/cart">
                    <a>
                        <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Box display="flex" alignItems="center">
                                <img src={shoppingBag} />
                                <NormalText semibold sx={{ color: 'black', ml: 1 }}>
                                    {userCart.data.count} {lowerCase(t('item', { count: userCart.data.count }))}
                                </NormalText>
                            </Box>
                            <Box>
                                <MediumText semibold sx={{ color: 'black' }}>
                                    {userCart.data.itemsTotalCost} {t('mkd')}
                                </MediumText>
                            </Box>
                        </Box>
                    </a>
                </Link>
            </Paper>
        </Box>
    );
}
