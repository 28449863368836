import { Region } from 'klikni-jadi-shared-stuff';
import { collection } from 'services/db/firestoreApi';

export const findRegionByLocation = async (latitude: number, longitude: number): Promise<Region> => {
    const regions = await collection<Region>('configuration/local/regions');
    if (!regions) {
        return null;
    }

    let found = null;
    for (const region of regions) {
        if (
            region?.coordinates &&
            google.maps.geometry.poly.containsLocation(
                { lat: latitude, lng: longitude },
                new google.maps.Polygon({ paths: region?.coordinates })
            )
        ) {
            found = region;
        }
    }
    return found;
};
