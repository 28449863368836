import { Box, Divider } from '@mui/material';
import ordersIcon from 'assets/svg/orders-icon.svg';
import reservationsIcon from 'assets/svg/reservations-icon.svg';
import favoritesIcon from 'assets/svg/favorites-icon.svg';
import walletIcon from 'assets/svg/wallet-icon.svg';
import logoutIcon from 'assets/svg/logout-icon.svg';
import manageIcon from 'assets/svg/manage-account-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import { NormalText } from './atoms/Typography';
import { ReactNode } from 'react';
import { hideMyProfileOptions } from 'redux/actions/internal/general/global';
import { useAuth } from 'context/authentication';
import HeaderPopup from './HeaderPopup';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import allergiesIcon from 'assets/svg/allergiesIcon.svg';

interface IMyProfileHeaderOptionsProps {}

const MyProfileHeaderOptions = ({}: IMyProfileHeaderOptionsProps) => {
    const globals = useSelector((state: RootState) => state.globals.data);
    const { logout } = useAuth();
    const { t } = useTranslation('common');
    const router = useRouter();

    const handleLogout = async () => {
        try {
            logout();
            if (router.asPath.startsWith('/profile')) {
                router.push('/home');
            }
            // router.push('/');
        } catch (e) {
            // an error
        }
    };

    return (
        <HeaderPopup visible={globals.myProfileOptionsShown}>
            <Box display="flex" flexDirection="column" height="100%" m={1} p={2}>
                <Item icon={ordersIcon} url="/profile/orders">
                    {t('order', { count: 10 })}
                </Item>
                <Item icon={reservationsIcon} url="/profile/reservations">
                    {t('reservation', { count: 10 })}
                </Item>
                <Item icon={favoritesIcon} url="/profile/favorites">
                    {t('favorites')}
                </Item>
                {/* <Item icon={reviewsIcon} url="/profile/reviews">
                    {t('review', { count: 10 })}
                </Item> */}
                <Item icon={walletIcon} url="/profile/wallet">
                    {t('wallet')}
                </Item>
                {/* <Item icon={notificationsIcon} url="/profile/notifications">
                    {t('notification', { count: 10 })}
                </Item> */}
                <Item icon={allergiesIcon} url="/profile/foods">
                    {t('allergy', { count: 2 })}
                </Item>
                <Item icon={manageIcon} url="/profile/manage">
                    {t('manageAccount')}
                </Item>
                <Box my={1}>
                    <Divider />
                </Box>
                {/* <Item icon={helpIcon} url="/profile/orders">
                    {t('help')}
                </Item> */}
                <Box display="flex" my={1} alignItems="center" className="cursor-pointer" onClick={handleLogout}>
                    <img src={logoutIcon} />
                    <Box ml={2}>
                        <NormalText>{t('logout')}</NormalText>
                    </Box>
                </Box>
            </Box>
        </HeaderPopup>
    );
};

interface IItemProps {
    icon: string;
    url: string;
    children: ReactNode;
}

const Item = ({ icon, url, children }: IItemProps) => {
    const dispatch = useDispatch();

    const router = useRouter();

    const handleOnClick = () => {
        dispatch(hideMyProfileOptions());
        router.push(url);
    };

    return (
        <Box
            display="flex"
            my={1}
            alignItems="center"
            className="cursor-pointer"
            sx={{ ':hover': { cursor: 'pointer' } }}
            onClick={handleOnClick}
        >
            <img src={icon} />
            <Box ml={2}>
                <NormalText>{children}</NormalText>
            </Box>
        </Box>
    );
};

export default MyProfileHeaderOptions;
