import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { useDialogContext } from 'context/dialog-context';

export const GenericDialog = () => {
    const { data, update, reset } = useDialogContext();

    const handleClose = () => {
        data.onCancel?.();
        reset();
    };

    const handleSuccess = () => {
        data.onSuccess?.();
        update('__data__', {
            dialogData: {
                ...data,
                alertVisible: false,
            },
            onSuccess: null,
        });
    };

    return (
        <div>
            <Dialog
                open={data?.dialogData?.alertVisible || false}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{data?.dialogData?.contentText}</DialogTitle>
                <DialogContent>
                    {/* <DialogContentText id="alert-dialog-description">{openedInfo?.contentText}</DialogContentText> */}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{data?.dialogData?.cancelText}</Button>
                    <Button onClick={handleSuccess} color="primary">
                        {data?.dialogData?.successText}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};
