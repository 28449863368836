import React from 'react';
import dynamic from 'next/dynamic';
import { isClientSide } from 'services/utils';
import { Location } from 'types/map';
import { LatLng } from 'leaflet';

const Map = dynamic(() => import('./address-map/Map'), {
    ssr: false,
});

export interface Address {
    fullAddress: string;
    longitude: number;
    latitude: number;
    streetNumber?: string;
}

interface IAddressMap {
    position: Location;
    onPositionChange: (position: LatLng) => void;
    onAddressChange?: (address: Address) => void; // Used on Register
}

const AddressMap = ({ position, onPositionChange, onAddressChange }: IAddressMap) => {
    return (
        <>{isClientSide() && <Map position={position} onPositionChange={onPositionChange} onAddressChange={onAddressChange} />}</>
    );
};

export default AddressMap;
