import React from 'react';
import { Box, styled, useTheme } from '@mui/system';

const Span = styled('span')(({ theme }) => ({
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
}));

const DividerWithText = ({ children }) => {
    const theme = useTheme();

    return (
        <Box display="flex" alignItems="center">
            <Box sx={{ borderBottom: `1px solid ${theme.palette.grey[500]}`, flexGrow: 1 }} />
            <Span>{children}</Span>
            <Box sx={{ borderBottom: `1px solid ${theme.palette.grey[500]}`, flexGrow: 1 }} />
        </Box>
    );
};
export default DividerWithText;
