import { ActionStatus } from 'core/design/ActionStatus';
import { ThunkAction } from 'redux-thunk';
import { buildActionType } from 'redux/actions/service/buildActionType';
import { GLOBALS_HANDLE } from 'redux/constants/global';
import { RootState } from 'redux/reducers';
import { PayloadAction } from 'redux/types/actionTypes';
import { Globals } from 'redux/types/internalTypes';

export const setSearchFocus = (focus: boolean): ThunkAction<{}, RootState, {}, PayloadAction<Globals>> => async (
    dispatch,
    getState
) => {
    const globals = getState().globals.data;

    const newGlobals = { ...globals, isSearchFocused: focus };

    dispatch({
        type: buildActionType(GLOBALS_HANDLE, ActionStatus.DONE),
        payload: newGlobals,
    });
};

export const setListingsView = (view: string): ThunkAction<{}, RootState, {}, PayloadAction<Globals>> => async (
    dispatch,
    getState
) => {
    const globals = getState().globals.data;

    const newGlobals = { ...globals, listingsView: view };

    dispatch({
        type: buildActionType(GLOBALS_HANDLE, ActionStatus.DONE),
        payload: newGlobals,
    });
};

export const showMyProfileOptions = (): ThunkAction<{}, RootState, {}, PayloadAction<Globals>> => async (dispatch, getState) => {
    const globals = getState().globals.data;

    if (!globals.myProfileOptionsShown) {
        const newGlobals = { ...globals, myProfileOptionsShown: true };

        dispatch({
            type: buildActionType(GLOBALS_HANDLE, ActionStatus.DONE),
            payload: newGlobals,
        });
    }
};

export const hideMyProfileOptions = (): ThunkAction<{}, RootState, {}, PayloadAction<Globals>> => async (dispatch, getState) => {
    const globals = getState().globals.data;

    if (globals.myProfileOptionsShown) {
        const newGlobals = { ...globals, myProfileOptionsShown: false };

        dispatch({
            type: buildActionType(GLOBALS_HANDLE, ActionStatus.DONE),
            payload: newGlobals,
        });
    }
};

export const showNotifications = (): ThunkAction<{}, RootState, {}, PayloadAction<Globals>> => async (dispatch, getState) => {
    const globals = getState().globals.data;

    if (!globals.isNotificationsOpen) {
        const newGlobals = { ...globals, isNotificationsOpen: true };

        dispatch({
            type: buildActionType(GLOBALS_HANDLE, ActionStatus.DONE),
            payload: newGlobals,
        });
    }
};
export const hideNotifications = (): ThunkAction<{}, RootState, {}, PayloadAction<Globals>> => async (dispatch, getState) => {
    const globals = getState().globals.data;

    if (globals.isNotificationsOpen) {
        const newGlobals = { ...globals, isNotificationsOpen: false };

        dispatch({
            type: buildActionType(GLOBALS_HANDLE, ActionStatus.DONE),
            payload: newGlobals,
        });
    }
};
export const showGallery = (): ThunkAction<{}, RootState, {}, PayloadAction<Globals>> => async (dispatch, getState) => {
    const globals = getState().globals.data;

    if (!globals.isGalleryOpen) {
        const newGlobals = { ...globals, isGalleryOpen: true };

        dispatch({
            type: buildActionType(GLOBALS_HANDLE, ActionStatus.DONE),
            payload: newGlobals,
        });
    }
};
export const hideGallery = (): ThunkAction<{}, RootState, {}, PayloadAction<Globals>> => async (dispatch, getState) => {
    const globals = getState().globals.data;

    if (globals.isGalleryOpen) {
        const newGlobals = { ...globals, isGalleryOpen: false };

        dispatch({
            type: buildActionType(GLOBALS_HANDLE, ActionStatus.DONE),
            payload: newGlobals,
        });
    }
};

export const showLoginModal = (): ThunkAction<{}, RootState, {}, PayloadAction<Globals>> => async (dispatch, getState) => {
    const globals = getState().globals.data;

    if (!globals.isLoginOpen) {
        const newGlobals = { ...globals, isLoginOpen: true };

        dispatch({
            type: buildActionType(GLOBALS_HANDLE, ActionStatus.DONE),
            payload: newGlobals,
        });
    }
};

export const hideLoginModal = (): ThunkAction<{}, RootState, {}, PayloadAction<Globals>> => async (dispatch, getState) => {
    const globals = getState().globals.data;

    if (globals.isLoginOpen) {
        const newGlobals = { ...globals, isLoginOpen: false };

        dispatch({
            type: buildActionType(GLOBALS_HANDLE, ActionStatus.DONE),
            payload: newGlobals,
        });
    }
};

export const showLoginModalRegisterStep = (): ThunkAction<{}, RootState, {}, PayloadAction<Globals>> => async (
    dispatch,
    getState
) => {
    const globals = getState().globals.data;

    if (!globals.isRegisterOpen) {
        const newGlobals = { ...globals, isRegisterOpen: true };

        dispatch({
            type: buildActionType(GLOBALS_HANDLE, ActionStatus.DONE),
            payload: newGlobals,
        });
    }
};

export const hideLoginModalRegisterStep = (): ThunkAction<{}, RootState, {}, PayloadAction<Globals>> => async (
    dispatch,
    getState
) => {
    const globals = getState().globals.data;

    if (globals.isRegisterOpen) {
        const newGlobals = { ...globals, isRegisterOpen: false };

        dispatch({
            type: buildActionType(GLOBALS_HANDLE, ActionStatus.DONE),
            payload: newGlobals,
        });
    }
};

export const showCart = (): ThunkAction<{}, RootState, {}, PayloadAction<Globals>> => async (dispatch, getState) => {
    const globals = getState().globals.data;

    if (!globals.isCartPopupOpen) {
        const newGlobals = { ...globals, isCartPopupOpen: true };

        dispatch({
            type: buildActionType(GLOBALS_HANDLE, ActionStatus.DONE),
            payload: newGlobals,
        });
    }
};

export const hideCart = (): ThunkAction<{}, RootState, {}, PayloadAction<Globals>> => async (dispatch, getState) => {
    const globals = getState().globals.data;

    if (globals.isCartPopupOpen) {
        const newGlobals = { ...globals, isCartPopupOpen: false };

        dispatch({
            type: buildActionType(GLOBALS_HANDLE, ActionStatus.DONE),
            payload: newGlobals,
        });
    }
};
