import { Box, Grid, Typography, useTheme } from '@mui/material';
import footerLogo from 'assets/svg/footer-logo.svg';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import { MediumText, SmallText } from 'components/atoms/Typography';
import { db } from 'config/firebase';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

const Footer = ({ isLandingPage = false }) => {
    const [phoneNumber, setPhoneNumber] = useState<string>('');

    const { t } = useTranslation('common');
    const theme = useTheme();
    const router = useRouter();

    useEffect(() => {
        db.collection('settings')
            .doc('company')
            .get()
            .then(res => {
                setPhoneNumber(res.data().contactPhone);
            });
    }, []);

    const handleTermsAndConditionsClick = () => {
        window.open(
            'https://firebasestorage.googleapis.com/v0/b/klikni-jadi-dev.appspot.com/o/policy%2FOpsti%20uslovi%20na%20koristenje.pdf?alt=media&token=b8916828-cea6-4e35-acb2-de0592ed43f5',
            '_blank'
        );
    };

    const handlePrivacyPolicyClick = () => {
        window.open(
            'https://firebasestorage.googleapis.com/v0/b/klikni-jadi-dev.appspot.com/o/policy%2FPolitika%20na%20kolacinja.pdf?alt=media&token=de389546-69d6-463d-a1e8-4f6c1d5c21aa',
            '_blank'
        );
    };

    const handleLegalInformationClick = () => {
        window.open(
            'https://firebasestorage.googleapis.com/v0/b/klikni-jadi-dev.appspot.com/o/policy%2FPolitika%20na%20privatnost%202021.pdf?alt=media&token=24a89833-5bec-481b-8229-fbd762ffc9ca',
            '_blank'
        );
    };

    const handleOpenFacebook = () => {
        window.open('https://www.facebook.com/takeawaymacedonia', '_blank');
    };

    return (
        <Box
            sx={{ backgroundColor: theme.palette.grey[50], color: 'black' }}
            px={{ xs: 1, md: isLandingPage ? 15 : 3 }}
            py={{ xs: 3, sm: 3, lg: 7 }}
        >
            <Box
                display="flex"
                sx={{
                    flexDirection: { xs: 'column-reverse', sm: 'row', md: 'row' },
                    justifyContent: { xs: 'center', sm: 'space-between' },
                }}
            >
                <Box>
                    <Box display="flex">
                        <img
                            src={footerLogo}
                            alt="logo"
                            style={{
                                width: '100px',
                            }}
                        />
                        <Box ml={2}>
                            <FacebookIcon
                                onClick={handleOpenFacebook}
                                sx={{
                                    marginRight: '10px',
                                    fontSize: '25px',
                                    ':hover': {
                                        cursor: 'pointer',
                                    },
                                }}
                            />
                            {/* <Box
                                component="img"
                                src={facebookIcon}
                                alt="fb"
                                sx={{
                                    marginRight: '20px',
                                }}
                            /> */}
                            <TwitterIcon sx={{ marginRight: '10px', fontSize: '25px' }} />
                            {/* <Box
                                component="img"
                                src={twitterIcon}
                                alt="twitter"
                                sx={{
                                    marginRight: '20px',
                                }}
                            /> */}
                            <InstagramIcon sx={{ marginRight: '10px', fontSize: '25px' }} />
                            {/* <Box
                                component="img"
                                src={instagramIcon}
                                alt="insta"
                                sx={{
                                    marginRight: '20px',
                                }}
                            /> */}
                        </Box>
                    </Box>
                    {/* <Box mt={{ xs: 2, md: 0 }} display="flex" flexDirection="column">
                        <Box pt={1} display="flex">
                            <img
                                src={google}
                                alt="google"
                                style={{
                                    width: '100px',
                                }}
                            />
                            <img
                                src={apple}
                                alt="apple"
                                style={{
                                    marginLeft: '10px',
                                    width: '100px',
                                }}
                            />
                        </Box>
                    </Box> */}
                </Box>
                <Box pl={{ sm: 5, md: 0 }} pr={{ sm: 5, md: 0 }} flex={1} mt={{ xs: 2, sm: 0 }}>
                    <Grid
                        container
                        sx={{
                            [theme.breakpoints.down('sm')]: {
                                justifyContent: 'center',
                            },
                            justifyContent: 'center',
                        }}
                    >
                        <Grid item xs={12} md="auto">
                            <Grid container>
                                <Grid item xs={8} md="auto">
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                            justifyContent: 'center',
                                            paddingRight: '50px',
                                            marginRight: '30px',
                                            marginBottom: '20px',
                                            [theme.breakpoints.down('lg')]: {
                                                marginRight: 0,
                                            },
                                        }}
                                    >
                                        <MediumText>{t('doItTogether')}</MediumText>
                                        <SmallText>{t('forCouriers')}</SmallText>
                                        <SmallText>{t('forPartners')}</SmallText>
                                        <SmallText
                                        // sx={{ ':hover': { cursor: 'pointer' } }}
                                        // onClick={() => router.push('/together/companies')}
                                        >
                                            {t('forCompanies')}
                                        </SmallText>
                                    </Box>
                                </Grid>
                                <Grid item xs={4} md="auto">
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                            justifyContent: 'center',
                                            paddingRight: '50px',
                                            marginRight: '30px',
                                            marginBottom: '20px',
                                            [theme.breakpoints.down('lg')]: {
                                                marginRight: 0,
                                            },
                                        }}
                                    >
                                        <MediumText>{t('company')}</MediumText>
                                        <SmallText
                                        // sx={{ ':hover': { cursor: 'pointer' } }}
                                        // onClick={() => router.push('/company/about-us')}
                                        >
                                            {t('about')}
                                        </SmallText>
                                        <SmallText
                                        // sx={{ ':hover': { cursor: 'pointer' }, width: 'max-content' }}
                                        // onClick={() => router.push('/company/our-team')}
                                        >
                                            {t('ourTeam')}
                                        </SmallText>
                                        <SmallText
                                            sx={{ ':hover': { cursor: 'pointer' } }}
                                            onClick={() => router.push('/explore/contact')}
                                        >
                                            {t('contactUs')}
                                        </SmallText>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md="auto">
                            <Grid container>
                                <Grid item xs={8} md="auto">
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                            justifyContent: 'center',
                                            paddingRight: '50px',
                                            marginRight: '30px',
                                            marginBottom: '20px',
                                            [theme.breakpoints.down('lg')]: {
                                                marginRight: 0,
                                            },
                                        }}
                                    >
                                        <MediumText>{t('legal')}</MediumText>
                                        <SmallText
                                        // sx={{ ':hover': { cursor: 'pointer' } }}
                                        // onClick={handleTermsAndConditionsClick}
                                        >
                                            {t('termsAndConditions')}
                                        </SmallText>
                                        <SmallText
                                        // sx={{ ':hover': { cursor: 'pointer' } }}
                                        // onClick={handlePrivacyPolicyClick}
                                        >
                                            {t('privacyPolicy')}
                                        </SmallText>
                                        <SmallText
                                        // sx={{ ':hover': { cursor: 'pointer' } }}
                                        // onClick={handleLegalInformationClick}
                                        >
                                            {t('legalInformation')}
                                        </SmallText>
                                    </Box>
                                </Grid>
                                <Grid item xs={4} md="auto">
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                            justifyContent: 'center',
                                            paddingRight: '50px',
                                            marginRight: '30px',
                                            marginBottom: '20px',
                                            [theme.breakpoints.down('lg')]: {
                                                marginRight: 0,
                                            },
                                        }}
                                    >
                                        <MediumText>{t('explore')}</MediumText>
                                        <SmallText
                                        // sx={{ ':hover': { cursor: 'pointer' }, width: 'max-content' }}
                                        // onClick={() => router.push('/explore/how-to-order')}
                                        >
                                            {t('howWorks')}
                                        </SmallText>
                                        <SmallText>{t('help')}</SmallText>
                                        <SmallText>{t('FAQs')}</SmallText>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
};

export default Footer;
