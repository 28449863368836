import { Backdrop, Box, Fade, Modal, Paper, Slide } from '@mui/material';
import { SxProps } from '@mui/system';
import { ReactElement, useRef, useState } from 'react';

interface IModalPopupProps {
    open: boolean;
    handleClose: () => void;
    children?: ReactElement<any, any>;
    minWidth?: any;
    minHeight?: string;
    maxWidth?: any;
    maxHeight?: string;
    boxStyle?: SxProps;
    alignItems?: any;
    justifyContent?: any;
}

export const ModalPopup = ({
    open,
    children,
    handleClose,
    minWidth = '50vw',
    minHeight = '50vh',
    maxWidth = '90vw',
    maxHeight = '90vh',
    alignItems,
    justifyContent,
}: IModalPopupProps) => {
    const [modalOpacity, setModalOpacity] = useState<number>(0);
    const intervalRef = useRef(null);

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '& .MuiPaper-root': {
                    outline: 'none!important',
                },
            }}
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={BackdropCustom}
            BackdropProps={{
                timeout: 500,
                invisible: false,
            }}
        >
            <Slide
                direction="up"
                in={open}
                mountOnEnter
                unmountOnExit
                onEnter={() => {
                    if (intervalRef.current) {
                        clearInterval(intervalRef.current);
                    }
                    intervalRef.current = setInterval(() => {
                        setModalOpacity(mo => mo + 0.05);
                    }, 10);
                }}
                onExit={() => {
                    if (intervalRef.current) {
                        clearInterval(intervalRef.current);
                    }
                    intervalRef.current = setInterval(() => {
                        setModalOpacity(mo => mo - 0.3);
                    }, 10);
                }}
                onEntered={() => {
                    if (intervalRef.current) {
                        clearInterval(intervalRef.current);
                    }
                    setModalOpacity(1);
                }}
                onExited={() => {
                    if (intervalRef.current) {
                        clearInterval(intervalRef.current);
                    }
                    setModalOpacity(0);
                }}
            >
                <Paper
                    sx={{
                        borderRadius: 4,
                        overflow: 'hidden',
                        opacity: modalOpacity,
                        transition: 'opacity 50ms ease-in-out',
                    }}
                >
                    <Box
                        minWidth={minWidth}
                        minHeight={minHeight}
                        maxWidth={maxWidth}
                        maxHeight={maxHeight}
                        overflow="auto"
                        display="flex"
                        flexDirection="column"
                        position="relative"
                        justifyContent={justifyContent || 'center'}
                        alignItems={alignItems || 'center'}
                    >
                        {children}
                    </Box>
                </Paper>
            </Slide>
        </Modal>
    );
};

const BackdropCustom = props => {
    return <Backdrop {...props} onTouchMove={e => e.preventDefault()} />;
};

export default ModalPopup;
