import { ActionStatus } from 'core/design/ActionStatus';
import { ThunkAction } from 'redux-thunk';
import { buildActionType } from 'redux/actions/service/buildActionType';
import { MAP_OPENED_PROPERTIES_HANDLE } from 'redux/constants/map';
import { RootState } from 'redux/reducers';
import { PayloadAction } from 'redux/types/actionTypes';
import { MapInfo } from 'redux/types/internalTypes';

export const openMapRestaurant = (id: string): ThunkAction<{}, RootState, {}, PayloadAction<MapInfo>> => async (
    dispatch,
    getState
) => {
    const mapInfo = getState().mapInfo.data;

    const newOpened = [...mapInfo.openedProperties];
    newOpened.push(id);

    const newInfo = { ...mapInfo, openedProperties: newOpened };

    dispatch({
        type: buildActionType(MAP_OPENED_PROPERTIES_HANDLE, ActionStatus.DONE),
        payload: newInfo,
    });
};

export const closeMapRestaurant = (id: string): ThunkAction<{}, RootState, {}, PayloadAction<MapInfo>> => async (
    dispatch,
    getState
) => {
    const mapInfo = getState().mapInfo.data;

    let newOpened = [...mapInfo.openedProperties];
    newOpened = newOpened.filter(op => op !== id);

    const newInfo = { ...mapInfo, openedProperties: newOpened };

    dispatch({
        type: buildActionType(MAP_OPENED_PROPERTIES_HANDLE, ActionStatus.DONE),
        payload: newInfo,
    });
};

export const setIsMapLoaded = (isLoaded: boolean): ThunkAction<{}, RootState, {}, PayloadAction<MapInfo>> => async (
    dispatch,
    getState
) => {
    const mapInfo = getState().mapInfo.data;

    if (isLoaded ? !mapInfo.isLoaded : mapInfo.isLoaded) {
        const newInfo = { ...mapInfo, isLoaded };

        dispatch({
            type: buildActionType(MAP_OPENED_PROPERTIES_HANDLE, ActionStatus.DONE),
            payload: newInfo,
        });
    }
};

export const setLoadError = (loadError: Error): ThunkAction<{}, RootState, {}, PayloadAction<MapInfo>> => async (
    dispatch,
    getState
) => {
    const mapInfo = getState().mapInfo.data;

    const newInfo = { ...mapInfo, loadError };

    dispatch({
        type: buildActionType(MAP_OPENED_PROPERTIES_HANDLE, ActionStatus.DONE),
        payload: newInfo,
    });
};
