import { isEqual } from 'lodash';
import { GeocoderService } from 'services/mapService';

interface GeocodeCurrentLocationResult {
    currentAddress: {
        longitude: number;
        latitude: number;
        fullAddress: string;
    };
    city: string;
}

export const geocodeLocation = (latitude: number, longitude: number): Promise<GeocodeCurrentLocationResult> => {
    const geocoderService = new GeocoderService();
    return new Promise((resolve, reject) => {
        geocoderService.geocode(
            { location: { lat: latitude, lng: longitude } },
            (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
                if (status === google.maps.GeocoderStatus.OK && results.length > 0) {
                    let found = results.find(add => add.types.includes('street_address') || add.types.includes('route'));
                    if (!found) {
                        found = results[0];
                    }
                    const city = found.address_components.find(el => isEqual(el.types, ['locality', 'political']));

                    resolve({
                        currentAddress: { latitude, longitude, fullAddress: found.formatted_address },
                        city: city?.long_name?.toLocaleLowerCase() || city?.long_name?.toLocaleLowerCase(),
                    });
                } else {
                    reject(new Error(`Geocode was not successful for the following reason: ${status}`));
                }
            }
        );
    });
};
