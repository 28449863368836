import { Box } from '@mui/material';
import theme from 'core/theme';
import { ReactNode } from 'react';
import { SmallText } from './Typography';

type StatusColor = 'primary' | 'secondary';

interface IBaseStatusProps {
    children?: ReactNode;
    color?: StatusColor;
    light?: boolean;
}

export const BaseStatus = ({ children, color = 'primary', light = false }: IBaseStatusProps) => {
    return (
        <Box
            sx={{
                borderRadius: '3px',
                color: theme.palette.grey[900],
                // padding: '2px 10px',
            }}
        >
            <SmallText semibold color="inherit">
                {children}
            </SmallText>
        </Box>
    );
};

const createStatus = (props: IBaseStatusProps, color: StatusColor) => BaseStatus({ ...props, color });

export const PrimaryStatus = (props: IBaseStatusProps) => createStatus(props, 'primary');
export const SecondaryStatus = (props: IBaseStatusProps) => createStatus(props, 'secondary');
