import { Autocomplete, Box, Button, Paper, TextField, Theme, useTheme } from '@mui/material';
import { useRouter } from 'next/router';
import styles from 'styles/search.module.css';
import { useEffect, useMemo, useState } from 'react';
import { MediumText, NormalText } from './atoms/Typography';
import deliveryIcon from 'assets/svg/delivery-icon.svg';
import groceriesIcon from 'assets/svg/groceries-icon.svg';
import drugstoreIcon from 'assets/svg/drugstore-icon.svg';
import Link from 'next/link';
import { Search, Star } from '@mui/icons-material';
import useTranslation from 'next-translate/useTranslation';
import { Restaurant } from 'klikni-jadi-shared-stuff';
import { db } from 'config/firebase';
import { throttle } from 'lodash';
import ResponsiveImage from './atoms/ResponsiveImage';
import { getDetailsRouterArguments } from 'services/application';
import { useDataTranslator } from 'hooks/useDataTranslator';
import { BoxProps, styled } from '@mui/system';
import { useCurrentLocationContext } from 'context/location';

interface INewSearchProps {
    placeholder?: string;
    mode?: 'dark' | 'light';
    initialValue?: string;
    onSearch?: (keyword: string) => void;
    initialType?: string;
    isTransparent?: boolean;
    isLandingPage?: boolean;
    isHeroSection?: boolean;
    routerQuery?: any;
}

interface SearchWrapperStyledProps extends BoxProps {
    theme?: Theme;
}

const SearchWrapperStyled = styled(Box)(({ theme }: SearchWrapperStyledProps) => ({
    transition: theme.transitions.create(['max-width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.complex,
    }),
    overflow: 'hidden',
}));

const NewSearch = ({
    placeholder: p,
    mode = 'dark',
    initialValue,
    onSearch,
    isTransparent = false,
    isLandingPage = false,
    isHeroSection = false,
    routerQuery,
}: INewSearchProps) => {
    const [value, setValue] = useState<string>(initialValue);
    const [optionValue] = useState(null);
    const { t } = useTranslation('common');
    const [restaurants, setRestaurants] = useState<Restaurant[]>([]);
    const router = useRouter();
    const theme = useTheme();
    const { translate } = useDataTranslator();

    const { data: location } = useCurrentLocationContext();

    const placeholder = useMemo(() => p || `${t('restaurant', { count: 1 })}, ${t('cuisine', { count: 1 })}`, [p, t]);

    const fetch = useMemo(
        () =>
            throttle((_value, callback) => {
                db.collection('/restaurants')
                    .where(`searchableIndex.${encodeURIComponent(_value)}`, '==', true)
                    .where('status.isActive', '==', true)
                    .where('region', '==', routerQuery?.city || 'any')
                    // .orderBy('sections.isSponsored', 'desc')
                    .limit(5)
                    .get()
                    .then(snap => {
                        if (!snap.empty) {
                            callback(
                                snap.docs
                                    .map(d => ({ ...d.data(), id: d.id } as Restaurant))
                                    .sort((a, b) => (b.sections?.isSponsored ? 1 : -1) - (a.sections?.isSponsored ? 1 : -1))
                            );
                        } else callback([]);
                    });
            }, 200),
        []
    );

    useEffect(() => {
        if (value === '') {
            setRestaurants([]);
            return undefined;
        }

        fetch(value, setRestaurants);
    }, [value, optionValue, fetch, setRestaurants]);

    useEffect(() => {
        setValue(initialValue || '');
        // setOptionValue(initialValue || '');
    }, [initialValue]);

    const handleOpenDrugstore = () => {
        router.push('/details/Viola-Apteka?id=WbqLJCMRo3SOJhaY8stD');
    };

    return (
        <Box>
            {/* <form onSubmit={handleSearch}> */}
            <Box
                display="flex"
                alignItems="center"
                width="100%"
                justifyContent={isHeroSection ? 'flex-start' : 'center'}
                pt={isHeroSection ? 5 : 0}
            >
                {/* <Box flex={1} display="flex" alignItems="center" justifyContent="center"> */}
                <SearchWrapperStyled
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    maxWidth={isTransparent ? 0 : { xs: 300, md: 300 }}
                    flex={1}
                >
                    <Autocomplete
                        id="auto-search"
                        sx={{ width: '100%' }}
                        getOptionLabel={option => option?.name?.en || ''}
                        filterOptions={x => x}
                        options={restaurants}
                        filterSelectedOptions
                        value={optionValue}
                        inputValue={value}
                        freeSolo
                        disableClearable
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        onChange={(event, newValue: any) => {
                            if (newValue !== undefined && newValue !== null) {
                                if (typeof newValue === 'string') {
                                    setValue(newValue);
                                    onSearch?.(newValue);
                                } else {
                                    router.push(
                                        getDetailsRouterArguments(newValue.id, newValue.name?.en || newValue.name?.mk || '')
                                    );
                                }
                            }
                        }}
                        onInputChange={(event, newInputValue) => {
                            setValue(newInputValue);
                        }}
                        renderInput={params => (
                            <Box
                                display="flex"
                                sx={{
                                    backgroundColor: isTransparent ? 'transparent' : 'rgba(32, 33, 37, 0.12)',
                                    border: mode === 'dark' ? `2px solid ${theme.palette.grey[300]}` : '2px solid transparent',
                                    px: 1.5,
                                    py: 0.7,
                                    borderRadius: 100,
                                    '&:has(input:focus)': {
                                        borderColor: 'primary.main',
                                        borderStyle: 'solid',
                                        borderWidth: 2,
                                    },
                                }}
                                flexDirection="row"
                                alignItems="center"
                                key={router.asPath}
                            >
                                <Box
                                    sx={{
                                        display: isTransparent ? 'none' : 'block',
                                    }}
                                >
                                    <MediumText style={{ display: 'flex', alignItems: 'center' }}>
                                        <Search />
                                    </MediumText>
                                </Box>
                                <Box flexGrow={1} ml={1} sx={{ display: isTransparent ? 'none' : 'flex' }} alignItems="center">
                                    <TextField
                                        {...params}
                                        className={styles['klik-search-input']}
                                        placeholder={placeholder}
                                        size="small"
                                        sx={{
                                            padding: 0,
                                            border: 'none',
                                            fontSize: { xs: '16px', md: '0.875rem' },
                                        }}
                                        InputProps={{
                                            ...params.InputProps,
                                            style: {
                                                padding: 0,
                                                minWidth: 0,
                                            },
                                            sx: {
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    borderStyle: 'none',
                                                },
                                            },
                                        }}
                                        inputProps={{
                                            ...params.inputProps,
                                            style: {
                                                padding: 0,
                                            },
                                        }}
                                    />
                                </Box>
                            </Box>
                        )}
                        renderOption={(props, option: Restaurant) => {
                            return (
                                <li key={option.id} {...props}>
                                    <Box display="flex" alignItems="center">
                                        {option.images?.logoUrl && (
                                            <ResponsiveImage
                                                src={option.images.logoUrl}
                                                style={{
                                                    width: `30px`,
                                                    height: `30px`,
                                                    borderRadius: '100%',
                                                }}
                                                options={{
                                                    width: 50,
                                                    height: 50,
                                                }}
                                                crop
                                            />
                                        )}
                                        <NormalText
                                            sx={{
                                                ml: '10px',
                                            }}
                                        >
                                            {translate(option.name)}
                                        </NormalText>
                                        {option.sections?.isSponsored && (
                                            <Star
                                                fontSize="large"
                                                sx={{
                                                    ml: 2,
                                                }}
                                                color="warning"
                                            />
                                        )}
                                    </Box>
                                </li>
                            );
                        }}
                        PaperComponent={props => (
                            <Paper
                                {...props}
                                sx={{
                                    mt: '10px',
                                    width: 'calc(100% + 46px)',
                                    marginLeft: '-38px',
                                    minWidth: '250px',
                                }}
                            />
                        )}
                    />
                </SearchWrapperStyled>
            </Box>
            {/* </Box> */}
            {/* </form> */}
        </Box>
    );
};

export default NewSearch;
