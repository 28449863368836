import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { UpdateActions } from 'redux/types/actionTypes';

export type actionMutator<P extends {}> = (data: Partial<P>) => Partial<P>;

export const useStreamlineDispatcher = <P extends {}>(actions: UpdateActions<P>) => {
    const dispatch = useDispatch();

    useEffect(() => {
        return () => {
            // dispatch(actions.reset());
        };
    }, []);

    return {
        update: (mutator?: actionMutator<P>) => (data: Partial<P>) => {
            const innerData = mutator ? mutator(data) : data;
            dispatch(actions.update(innerData));
        },
    };
};
