import { dialogInfoActions } from 'redux/actions/internal/streamline';
import { DialogState } from 'redux/types/internalTypes';
import { useStreamlineDispatcher } from './useStreamlineDispatcher';

export const useStreamline = () => {
    const dialogInfoDispatcher = useStreamlineDispatcher<DialogState>(dialogInfoActions);

    return {
        updateDialogInfo: dialogInfoDispatcher.update(),
    };
};
