import { Button, Box, useTheme, useMediaQuery, IconButton, Container } from '@mui/material';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import whiteLogo from 'assets/svg/white-logo.svg';
import blueLogo from 'assets/svg/TakeawayLogo.svg';
import Link from 'next/link';
import { useRouting } from 'services/routing';
import { setSearchFocus } from 'redux/actions/internal/general/global';
import { scrollOffsetAction } from 'redux/actions/internal/general/scroller';
import ShoppingBag from 'components/ShoppingBag';
import MyProfileHeader from 'components/MyProfileHeader';
import NewSearch from 'components/NewSearch';
import Render from 'components/atoms/Render';
import deliveryIcon from 'assets/svg/delivery-icon.svg';
import drugstoreIcon from 'assets/svg/drugstore-icon.svg';
import groceriesIcon from 'assets/svg/groceries-icon.svg';
import LanguagePopover from 'components/molecules/LanguagePopover';
import NewSearchMobile from 'components/NewSearchMobile';
import favIcon from 'assets/svg/favicon.svg';
import { useCurrentLocationContext } from 'context/location';
import GoogleMapsApiContainer from 'containers/google-maps/GoogleMapsApiContainer';
import DeliveryTo from 'components/DeliveryTo';
import { useLocationContext } from 'context/current-location';
import { restExecutor } from 'services/rest-executor/executor';
import { Region } from 'klikni-jadi-shared-stuff';
import { useScrollerContext } from 'context/scroller-context';
import { collection } from 'services/db/firestoreApi';

interface IHeaderProps {
    showSearch?: boolean;
    transparentSearch?: boolean;
    routerQuery?: any;
    url?: string;
    isLandingPage?: boolean;
}

const Header: FunctionComponent<IHeaderProps> = ({
    transparentSearch = false,
    routerQuery,
    url = '',
    isLandingPage = false,
}: IHeaderProps) => {
    const dispatch = useDispatch();
    const routing = useRouting();
    const theme = useTheme();
    const matched = useMediaQuery(theme.breakpoints.down('md'));
    const matchedXs = useMediaQuery(theme.breakpoints.down('sm'));
    const { update, data: location } = useLocationContext();
    const { isLoaded } = useSelector((state: RootState) => state.mapInfo.data);
    const [href, setHref] = useState<any>('/home');
    const cart = useSelector((state: RootState) => state.userCart);

    const user = useSelector((state: RootState) => state.currentUser);

    const searchFocused = useSelector((state: RootState) => state.globals.data.isSearchFocused);
    const { data } = useScrollerContext();

    const isHeaderTransparentZone = data.isHeaderTransparent;
    const { update: updateScroller, data: scrollerData } = useScrollerContext();

    const handleScroll = () => {
        const offset = window.pageYOffset;
        const state = scrollerData;

        if (+offset > 150) {
            if (!state.isSearchInHeader) {
                updateScroller('__data__', {
                    isHeaderTransparent: false,
                    isSearchInHeader: true,
                });
            }
        } else {
            if (state.isSearchInHeader) {
                updateScroller('__data__', {
                    isHeaderTransparent: true,
                    isSearchInHeader: false,
                });
            }
        }

        // dispatch(scrollOffsetAction(window.pageYOffset));
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [scrollerData.isSearchInHeader]);

    const isTransparent = useMemo(
        () => transparentSearch && isHeaderTransparentZone,
        [transparentSearch, isHeaderTransparentZone]
    );

    useEffect(() => {
        if (cart?.data?.host?.id) {
            if (!location?.location) {
                const address = cart?.data?.host?.address;
                collection<Region>('configuration/local/regions').then(regions => {
                    let found;
                    for (const region of regions) {
                        if (
                            region?.coordinates &&
                            google.maps.geometry.poly.containsLocation(
                                { lat: address?.location?.latitude, lng: address?.location?.longitude },
                                new google.maps.Polygon({ paths: region.coordinates })
                            )
                        ) {
                            found = region?.id;
                        }
                    }
                    update('__data__', {
                        location: {
                            latitude: address?.location?.latitude,
                            longitude: address.location?.longitude,
                            fullAddress: address.fullAddress,
                        },
                        region: found,
                        addressId: address.id,
                    });
                });
            }
        }
    }, [cart?.data?.host?.id, location]);

    useEffect(() => {
        if (isLandingPage) {
            if (location.location) {
                setHref({
                    pathname: '/partners/discover',
                    query: {
                        ...(location.location?.latitude
                            ? {
                                  nearby: `${location.location?.latitude},${location.location?.longitude}`,
                              }
                            : {}),
                        ...(location.region ? { city: location.region } : {}),
                        ...(location.addressId ? { a: location.addressId } : {}),
                    },
                });
            } else {
                setHref('/partners/discover');
            }
        } else if (url.startsWith('/partners')) {
            setHref('/home');
        } else if (location.location) {
            setHref({
                pathname: '/partners/discover',
                query: {
                    ...(location.location?.latitude
                        ? {
                              nearby: `${location.location?.latitude},${location.location?.longitude}`,
                          }
                        : {}),
                    ...(location.region ? { city: location.region } : {}),
                    ...(location.addressId ? { a: location.addressId } : {}),
                },
            });
        } else {
            const executor = restExecutor('/restaurants/nearest-region', 'v2');
            executor.get().then(response => {
                if (response.success) {
                    const region = response.data.region as Region;
                    if (region?.location) {
                        setHref({
                            pathname: '/partners/discover',
                            query: {
                                nearby: `${region.location?.position?.latitude},${region.location?.position?.longitude}`,
                                city: region?.id,
                            },
                        });
                    }
                }
            });
        }
    }, [location]);

    const handleSearch = keyword => {
        if (url.startsWith('/partners')) {
            routing.push({
                to: '/partners',
                queries: {
                    search: keyword,
                    city: routerQuery?.city,
                    nearby: routerQuery?.nearby,
                },
            });
        } else {
            if (location.location) {
                routing.push({
                    to: '/partners',
                    queries: {
                        search: keyword,
                        city: location?.region || null,
                        nearby: `${location.location?.latitude},${location.location?.longitude}`,
                        a: location?.addressId || null,
                    },
                });
            } else {
                const executor = restExecutor('/restaurants/nearest-region', 'v2');
                executor.get().then(response => {
                    if (response.success) {
                        const region = response.data.region as Region;
                        if (region?.location) {
                            routing.push({
                                to: '/partners',
                                queries: {
                                    search: keyword,
                                    city: region?.id,
                                    nearby: `${region.location?.position?.latitude},${region.location?.position?.longitude}`,
                                },
                            });
                        }
                    }
                });
            }
        }
    };

    const handleSearchUnfocus = () => {
        if (searchFocused) dispatch(setSearchFocus(false));
    };

    return (
        <Box
            sx={
                {
                    // minHeight: '80px',
                }
            }
            display="flex"
            flexDirection="column"
        >
            <Box
                px={{ xs: 0, md: isLandingPage ? 0 : 0 }}
                py={1}
                pt={1.3}
                sx={{
                    borderBottom: isTransparent ? '' : '1px solid #eaeaea',
                    zIndex: 1000,
                    // position: 'relative',
                    minHeight: matched ? '50px' : '70px',
                    // height: showSearch || isSearchInHeader ? '100px' : '70px',
                    backgroundColor: isTransparent ? 'transparent' : 'white',
                    background: isTransparent
                        ? 'linear-gradient(to bottom,  rgba(0, 0, 0, 0.5) 0%, rgba(255, 255, 255, 0) 100%)'
                        : '',
                    transition: 'background 0.3s linear',
                }}
                className="app-header"
            >
                <Container maxWidth={isLandingPage ? 'md' : 'lg'}>
                    <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                        height="100%"
                        minHeight="50px"
                    >
                        {/* <Box sx={{ display: {xs: 'block', md: 'none'} }} >
                                <Box>
                                    <DrawerSidebar transparentSearch={transparentSearch} />
                                </Box>
                            </Box> */}
                        {/* <Box sx={{ display: {xs: 'none', md: 'block'} }} > */}
                        <Box flex={{ xs: undefined, md: 1 }} minWidth={0} display="flex">
                            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
                                <Box>
                                    <Box>
                                        <Link href={href}>
                                            <a>
                                                <Button>
                                                    <img
                                                        src={isTransparent ? whiteLogo : blueLogo}
                                                        alt="logo"
                                                        style={{ height: '35px', width: 'auto' }}
                                                    />
                                                </Button>
                                            </a>
                                        </Link>
                                    </Box>
                                </Box>
                            </Box>
                            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                                <Box>
                                    <Box>
                                        <Link href={href}>
                                            <a>
                                                <Button>
                                                    <img src={favIcon} alt="logo" height="35" width="auto" />
                                                </Button>
                                            </a>
                                        </Link>
                                    </Box>
                                </Box>
                            </Box>
                            <Box flex={1} minWidth={0} display="flex" ml={2}>
                                <Box width="100%">
                                    {url.startsWith('/partners') && (
                                        <Box display={{ xs: 'none', md: 'block' }}>
                                            <GoogleMapsApiContainer>
                                                {isLoaded && <DeliveryTo routerQuery={routerQuery} />}
                                            </GoogleMapsApiContainer>
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                        {(url.startsWith('/partners') || url.startsWith('/details')) && (
                            <Box sx={{ display: { xs: 'block', md: 'block' } }} flex={1}>
                                <Box flex={1}>
                                    <NewSearch
                                        isLandingPage={isLandingPage}
                                        onSearch={handleSearch}
                                        initialValue={routerQuery.search || ''}
                                        initialType={routerQuery.type}
                                        isTransparent={isTransparent}
                                        routerQuery={routerQuery}
                                    />
                                </Box>
                            </Box>
                        )}
                        {/* </Box> */}

                        {/* <Render when={!!user.data && !isLandingPage}>
                        <Box sx={{ display: { xs: 'none', md: 'block' } }} flex={1}>
                            <Box flex={1}>
                                <NewSearch
                                    isLandingPage={isLandingPage}
                                    onSearch={handleSearch}
                                    initialValue={routerQuery.search || ''}
                                    initialType={routerQuery.type}
                                    isTransparent={isTransparent}
                                    routerQuery={routerQuery}
                                />
                            </Box>
                        </Box>
                    </Render> */}

                        {/* <Box sx={{ display: { xs: 'block', md: 'none' } }} flex={1}>
                            <Render when={url.startsWith('/listings')}>
                                <NewSearchMobile routerQuery={routerQuery} />
                            </Render>
                            <Render when={!url.startsWith('/listings')}>
                                <Box position="relative" width="100%" display="flex" justifyContent="center">
                                    <Link
                                        href={{
                                            pathname: '/listings',
                                            query: location?.coords?.latitude && {
                                                nearby: `${[location.coords.latitude, location.coords.longitude].join(',')}`,
                                            },
                                        }}
                                    >
                                        <a>
                                            <IconButton sx={{ marginTop: '-5px' }} size="large">
                                                <Box
                                                    component="img"
                                                    src={deliveryIcon}
                                                    sx={{
                                                        width: 30,
                                                    }}
                                                    alt=""
                                                />
                                            </IconButton>
                                        </a>
                                    </Link>
                                </Box>
                            </Render>
                        </Box> */}
                        <Box
                            display="flex"
                            justifyContent="flex-end"
                            alignItems="center"
                            pl={{ xs: 1, md: 3 }}
                            flex={{ xs: undefined, md: 1 }}
                        >
                            {/* {stateIsLoaded(groupCartInvited) && (
                                    <Box px={3}>
                                        <NormalText semibold color="primary">
                                            Group delivery
                                        </NormalText>
                                    </Box>
                                )} */}
                            <Box mr={2}>
                                <LanguagePopover transparent={transparentSearch} />
                            </Box>
                            {/* <Box sx={{ display: {xs: 'none', md: 'block'} }} >
                                    <NotificationsHeader transparentSearch={transparentSearch} />
                                </Box> */}
                            <Box
                                sx={{
                                    display: {
                                        xs: 'none',
                                        sm: 'block',
                                    },
                                }}
                            >
                                <ShoppingBag transparentSearch={matchedXs ? !transparentSearch : transparentSearch} />
                            </Box>
                            <MyProfileHeader transparentSearch={transparentSearch} />
                        </Box>
                    </Box>
                </Container>
            </Box>
            {searchFocused && (
                <Box
                    sx={{
                        backgroundColor: 'rgba(0,0,0,0.5)',
                        // position: 'absolute',
                        // top: 50,
                        // bottom: 0,
                        // left: 0,
                        // right: 0,
                        zIndex: 999,
                    }}
                    onClick={handleSearchUnfocus}
                    flex={1}
                ></Box>
            )}
            <GoogleMapsApiContainer>
                <div></div>
            </GoogleMapsApiContainer>
        </Box>
    );
};

export default Header;
