import React, { useRef, useEffect } from 'react';

interface IProps {
    children?: JSX.Element;
    onOutsideClick?: (event: MouseEvent) => void;
}

const InsideComponent = ({ children, onOutsideClick = () => {} }: IProps) => {
    const node = useRef<HTMLDivElement>();
    useEffect(() => {
        const handleClick = (e: MouseEvent) => {
            if (node.current) {
                if (node.current!.contains(e.target as Node)) {
                    return;
                }
                onOutsideClick(e);
            }
        };
        if (typeof document !== 'undefined') {
            document?.removeEventListener('mousedown', handleClick);
            document?.addEventListener('mousedown', handleClick);
        }

        return () => {
            if (typeof document !== 'undefined') {
                document?.removeEventListener('mousedown', handleClick);
            }
        };
    }, [onOutsideClick]);

    return (
        <div ref={node} style={{ height: '100%' }}>
            {children}
        </div>
    );
};

export default InsideComponent;
