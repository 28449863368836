import { Box, FormControl, IconButton, InputAdornment, InputProps, OutlinedInput, Typography } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { forwardRef, MouseEvent, MutableRefObject, Ref, useEffect, useState } from 'react';
import { InputVariantsLiteral } from 'types/inputs';
import { ActionButton } from './Buttons';
import useTranslation from 'next-translate/useTranslation';

interface IBaseInputProps extends Omit<InputProps, 'onChange' | 'variant' | 'type' | 'ref'> {
    initialValue?: string;
    onChange?: (value: string) => void;
    error?: boolean;
    variant?: InputVariantsLiteral;
    focused?: boolean;
    ref?: Ref<HTMLInputElement>;
}

export const BaseInput = forwardRef<HTMLInputElement, IBaseInputProps>(
    (
        { placeholder, onChange, initialValue = '', error = false, variant = 'basic', focused = false, ...rest }: IBaseInputProps,
        ref
    ) => {
        const [value, setValue] = useState<string>(initialValue);
        const [type, setType] = useState<'text' | 'password'>('text');

        useEffect(() => {
            if (variant === 'password') setType('password');
        }, [variant]);

        useEffect(() => {
            if (focused) {
                (ref as MutableRefObject<HTMLInputElement>).current?.focus();
            }
        }, [focused]);

        useEffect(() => {
            if (onChange) onChange(value);
        }, [value]);

        const handlePasswordVisibility = () => {
            setType(type === 'text' ? 'password' : 'text');
        };

        const handleMouseDownPassword = (event: MouseEvent) => {
            event.preventDefault();
        };

        return (
            <FormControl size="small" variant="outlined" fullWidth={true}>
                <OutlinedInput
                    inputRef={ref}
                    placeholder={placeholder}
                    sx={{
                        backgroundColor: 'white',
                    }}
                    fullWidth={true}
                    value={value}
                    onChange={event => setValue(event.target.value)}
                    error={error}
                    {...rest}
                    type={type}
                    endAdornment={
                        variant === 'basic' ? null : (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handlePasswordVisibility}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                    size="large"
                                >
                                    {(type === 'text' && <Visibility />) || <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }
                />
            </FormControl>
        );
    }
);

export const Action = forwardRef<HTMLInputElement, Omit<IBaseInputProps, 'variant'>>(
    ({ placeholder, onChange, initialValue = '', error = false, focused = false, onSubmit, ...rest }: IBaseInputProps, ref) => {
        const [value, setValue] = useState<string>(initialValue);
        const { t } = useTranslation('common');

        useEffect(() => {
            if (focused) {
                (ref as MutableRefObject<HTMLInputElement>).current?.focus();
            }
        }, [focused]);

        useEffect(() => {
            if (onChange) onChange(value);
        }, [value]);

        const handleSubmit = e => {
            e.preventDefault();
            if (onSubmit) onSubmit(e);
        };

        return (
            <form onSubmit={handleSubmit}>
                <FormControl size="small" variant="outlined" fullWidth={true}>
                    <Box display="flex">
                        <OutlinedInput
                            inputRef={ref}
                            placeholder={placeholder}
                            sx={{ borderRadius: '0px' }}
                            fullWidth={true}
                            value={value}
                            onChange={event => setValue(event.target.value)}
                            error={error}
                            {...rest}
                        />
                        <ActionButton type="submit" disabled={rest.disabled}>
                            {t('apply')}
                        </ActionButton>
                    </Box>
                </FormControl>
            </form>
        );
    }
);

export const BasicInput = forwardRef<HTMLInputElement, IBaseInputProps>((props, ref) => (
    <BaseInput {...props} variant="basic" ref={ref} />
));
export const PasswordInput = forwardRef<HTMLInputElement, IBaseInputProps>((props, ref) => (
    <BaseInput {...props} variant="password" ref={ref} />
));
export const ActionInput = forwardRef<HTMLInputElement, IBaseInputProps>((props, ref) => <Action {...props} ref={ref} />);

interface IInputOptionalLabelProps {
    position?: 'left' | 'right' | 'center' | 'end' | 'start';
}

export const InputOptionalLabel = ({ position = 'end' }: IInputOptionalLabelProps) => {
    const { t } = useTranslation('common');
    return (
        <Typography
            style={{
                textAlign: position,
                fontSize: '13px',
                color: '#979797',
                fontWeight: 500,
                marginBottom: '-4px',
            }}
        >
            {t('optional')}
        </Typography>
    );
};
