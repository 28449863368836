import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import loginIcon from 'assets/svg/login-icon.svg';
import loginIconWhite from 'assets/svg/login-icon-white.svg';
import userIconWhite from 'assets/svg/header-user-icon-white.svg';
import userIcon from 'assets/svg/header-user-icon.svg';
import InsideComponent from './InsideComponent';
import { useLoginFlow } from 'hooks/useLoginFlow';
import { hideMyProfileOptions, showMyProfileOptions } from 'redux/actions/internal/general/global';
import MyProfileHeaderOptions from './MyProfileHeaderOptions';
import { useAuth } from 'context/authentication';
import { useScrollerContext } from 'context/scroller-context';

interface IMyProfileHeaderProps {
    transparentSearch: boolean;
}

const MyProfileHeader = ({ transparentSearch }: IMyProfileHeaderProps) => {
    const dispatch = useDispatch();
    const { isAuthenticated } = useAuth();

    const authentication = useLoginFlow();
    const globals = useSelector((state: RootState) => state.globals.data);

    const { data } = useScrollerContext();

    const isHeaderTransparentZone = data.isHeaderTransparent;

    const handleClickLogin = () => {
        authentication.openLogin();
    };

    const handleClickUser = () => {
        if (globals.myProfileOptionsShown) {
            dispatch(hideMyProfileOptions());
        } else {
            dispatch(showMyProfileOptions());
        }

        // try {
        //     await firebase.auth().signOut();
        //     // router.push('/');
        // } catch (e) {
        //     // an error
        // }
    };

    const handleOutsideClick = () => {
        dispatch(hideMyProfileOptions());
    };

    return (
        <Box position="relative">
            <InsideComponent onOutsideClick={handleOutsideClick}>
                <Box>
                    {(!isAuthenticated || authentication.isLoginOpen) && (
                        <Box
                            sx={{
                                cursor: 'pointer',
                            }}
                            onClick={handleClickLogin}
                        >
                            {(transparentSearch && isHeaderTransparentZone && (
                                <img src={loginIconWhite} alt="login button" />
                            )) || <img src={loginIcon} alt="login button" />}
                        </Box>
                    )}
                    {isAuthenticated && !authentication.isLoginOpen && (
                        <>
                            <Box sx={{ cursor: 'pointer' }} onClick={handleClickUser}>
                                {(transparentSearch && isHeaderTransparentZone && <img src={userIconWhite} />) || (
                                    <img src={userIcon} alt="profile" />
                                )}
                            </Box>
                            <MyProfileHeaderOptions />
                        </>
                    )}
                </Box>
            </InsideComponent>
        </Box>
    );
};

export default MyProfileHeader;
