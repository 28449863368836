import { Box } from '@mui/material';
import { SmallText } from 'components/atoms/Typography';
import useTranslation from 'next-translate/useTranslation';

interface IReviewDisplayProps {
    reviewOption?: number;
    title?: string;
}

const ReviewDisplay = ({ reviewOption, title }: IReviewDisplayProps) => {
    const { t } = useTranslation('common');

    return (
        <div>
            <Box display="flex" flexDirection="column" alignItems="center">
                <SmallText semibold>{title}</SmallText>
                <Box p={1} display="flex">
                    <Box pr={5}>
                        <SmallText
                            sx={{
                                color: reviewOption === 1 ? 'primary.main' : 'unset',
                                opacity: reviewOption === 1 ? 1 : 0.3,
                            }}
                            semibold
                        >
                            {t('yes')}
                        </SmallText>
                    </Box>
                    <Box>
                        <SmallText
                            sx={{
                                color: reviewOption === 0 ? 'primary.main' : 'unset',
                                opacity: reviewOption === 0 ? 1 : 0.3,
                            }}
                            semibold
                        >
                            {t('no')}
                        </SmallText>
                    </Box>
                </Box>
            </Box>
        </div>
    );
};

export default ReviewDisplay;
