import { Box, Grid, TextField, Checkbox } from '@mui/material';
import { PrimaryButton } from 'components/atoms/Buttons';
import firebase, { db } from 'config/firebase';
import { PrimaryStatus } from 'components/atoms/Status';
import { LargeText, MediumText, NormalText, SmallText } from 'components/atoms/Typography';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import closeButton from 'assets/svg/close-review.svg';
import VoteToggler from 'components/molecules/VoteToggler';
import DividerWithText from 'components/molecules/DividerWithText';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import { LocaleStrings, Review } from 'klikni-jadi-shared-stuff';
import ReviewDisplay from 'components/molecules/ReviewDisplay';
import { useReviews } from 'hooks/useReviews';
import { CartItems } from 'klikni-jadi-shared-stuff';
import _ from 'lodash';
import useTranslation from 'next-translate/useTranslation';
import { useDataTranslator } from 'hooks/useDataTranslator';

interface IReviewModalProps {
    date?: firebase.firestore.Timestamp;
    orderNo?: string;
    items?: CartItems;
    onClose?: () => void;
    orderId?: string;
    restaurantId?: string;
    foodMenuId?: string;
    restaurantName?: LocaleStrings;
}

const ReviewModal = ({ date, orderNo, items, onClose, orderId, restaurantId, foodMenuId, restaurantName }: IReviewModalProps) => {
    const currentUser = useSelector((state: RootState) => state.currentUser);
    const [review, setReview] = useState<Review>(null);
    const { translate } = useDataTranslator();

    const reviewState = useReviews(items, review);

    useEffect(() => {
        db.collection('/reviews')
            .where('orderId', '==', orderId)
            .get()
            .then(snap => {
                if (!snap.empty) {
                    const _doc = snap.docs[0];
                    setReview(_doc.data() as Review);
                }
            });
    }, [orderId]);

    const { t } = useTranslation('common');

    const closeReview = () => {
        if (onClose) onClose();
    };

    const onSave = () => {
        reviewState.saveToFirestore(orderId, restaurantId, foodMenuId, currentUser.data.id, currentUser.data.firstName);
        if (onClose) onClose();
    };

    const handleActiveClass = (id, num) => {
        if (reviewState.reviewsArray?.find(item => item[0] === id && item[1].recommend === num)) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <div>
            <Box sx={{ backgroundColor: 'white', borderRadius: '10px' }} flex={1} minHeight="0px">
                <Box display="flex" justifyContent="flex-end" onClick={closeReview}>
                    <Box
                        component="img"
                        src={closeButton}
                        sx={{
                            paddingTop: '8px',
                            paddingRight: '8px',
                            cursor: 'pointer',
                        }}
                    />
                </Box>
                <Box>
                    <Box display="flex" flexDirection="column" alignItems="center">
                        {/* <Box>
                            <LargeText semibold>{t('review', { count: 1 })}</LargeText>
                        </Box> */}
                        <Box>
                            <LargeText semibold>{translate(restaurantName)}</LargeText>
                        </Box>
                        <SmallText>
                            Date: <b>{moment(date.toDate()).format('DD MMMM YYYY')}</b>
                            ,&nbsp;&nbsp;
                            {moment(date.toDate()).format('HH:mm')}
                        </SmallText>
                        <SmallText semibold>{t('onlineOrder')}</SmallText>
                        <Box p={0.5}>
                            <PrimaryStatus>ID #{orderNo}</PrimaryStatus>
                        </Box>
                    </Box>
                    <Box
                        p={3}
                        sx={{
                            color: theme => theme.palette.text.secondary,
                        }}
                    >
                        <Box pb={2}>
                            <DividerWithText>
                                <SmallText semibold>{t('restaurant', { count: 1 })}</SmallText>
                            </DividerWithText>
                        </Box>

                        <Grid container spacing={3} direction="row" alignItems="center" justifyContent="space-between">
                            {reviewState.review ? (
                                <>
                                    {reviewState.restaurantDisplay.map(obj => (
                                        <Grid item xs={6}>
                                            <ReviewDisplay reviewOption={obj.value} title={obj.title} />{' '}
                                        </Grid>
                                    ))}
                                    <Grid item xs={6} style={{ alignSelf: 'stretch' }}>
                                        {' '}
                                        <Box
                                            display="flex"
                                            justifyContent="space-between"
                                            pb={1}
                                            height="100%"
                                            alignItems="center"
                                            flexDirection="column"
                                        >
                                            <SmallText color="textPrimary">
                                                <b>{t('comment', { count: 1 })}: </b>
                                            </SmallText>
                                            <Box>
                                                <SmallText color="textPrimary">
                                                    {reviewState.review.restaurantReview.comment
                                                        ? reviewState.review.restaurantReview.comment
                                                        : '/'}
                                                </SmallText>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </>
                            ) : (
                                <>
                                    {reviewState.restaurantToggle &&
                                        reviewState.restaurantToggle.map(obj => (
                                            <Grid item xs={6}>
                                                <VoteToggler
                                                    value={reviewState.restaurant[obj.key]}
                                                    title={obj.title}
                                                    onChange={value => reviewState.handleChoice(obj.type, obj.key, value)}
                                                />
                                            </Grid>
                                        ))}
                                    <Grid item xs={6} style={{ alignSelf: 'stretch' }}>
                                        <Box display="flex" flexDirection="column" alignItems="center" height="100%">
                                            <Box flex={1} minHeight={0}>
                                                <SmallText semibold color="textPrimary">
                                                    {t('leaveAComment')}
                                                </SmallText>
                                            </Box>
                                            <Box p={1}>
                                                <TextField
                                                    id="outlined-basic"
                                                    label={t('leaveAComment')}
                                                    variant="outlined"
                                                    value={reviewState.restaurant.comment}
                                                    onChange={e => reviewState.handleCommentChange(e, 'restaurant')}
                                                    fullWidth
                                                    size="small"
                                                />
                                            </Box>
                                        </Box>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </Box>
                    <Box p={3}>
                        <DividerWithText>
                            <SmallText
                                sx={{
                                    color: theme => theme.palette.text.secondary,
                                }}
                                semibold
                            >
                                {t('food')}
                            </SmallText>
                        </DividerWithText>
                        <Box p={0} px={{ xs: 0, md: 4 }} display="flex" flexDirection="column">
                            <Box display="flex" justifyContent="space-between" alignItems="center" pb={1} pt={2}>
                                <Box flex={1}>
                                    <NormalText semibold color="textPrimary">
                                        {t('wouldYouRecommendThis')}
                                    </NormalText>
                                </Box>
                                {!reviewState.review && (
                                    <Box display="flex" justifyContent="flex-end">
                                        <Box>
                                            {' '}
                                            <NormalText>{t('yes')}</NormalText>
                                        </Box>
                                        <Box pl="28px" pr="30px">
                                            {' '}
                                            <NormalText>{t('no')}</NormalText>
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                            {_.toPairs(reviewState.items).map(([id, item]) => (
                                <Box display="flex" justifyContent="space-between" alignItems="center" key={id}>
                                    <Box flex={1} display="flex">
                                        <NormalText color="textPrimary">{item?.name?.en}</NormalText>
                                    </Box>
                                    <Box display="flex" justifyContent="flex-end">
                                        {reviewState.review ? (
                                            <>
                                                <Box>
                                                    <SmallText
                                                        sx={{
                                                            color: theme =>
                                                                handleActiveClass(item.id, 1)
                                                                    ? 'primary.main'
                                                                    : theme.palette.text.secondary,
                                                            opacity: handleActiveClass(item.id, 1) ? 1 : 0.3,
                                                        }}
                                                        semibold
                                                    >
                                                        {t('yes')}
                                                    </SmallText>
                                                </Box>

                                                <Box px={3}>
                                                    <SmallText
                                                        sx={{
                                                            color: theme =>
                                                                handleActiveClass(item.id, 0)
                                                                    ? 'primary.main'
                                                                    : theme.palette.text.secondary,
                                                            opacity: handleActiveClass(item.id, 0) ? 1 : 0.3,
                                                        }}
                                                        semibold
                                                    >
                                                        {t('no')}
                                                    </SmallText>
                                                </Box>
                                            </>
                                        ) : (
                                            <>
                                                <Box>
                                                    <Checkbox
                                                        sx={{
                                                            transform: 'scale(1.5)',
                                                            padding: '5px',
                                                        }}
                                                        checked={reviewState.food[item.id]?.recommend === 1}
                                                        onChange={() => reviewState.handleFoodReviewChange(item.id, true)}
                                                        color="primary"
                                                        inputProps={{
                                                            'aria-label': 'primary checkbox',
                                                        }}
                                                    />
                                                </Box>
                                                <Box px={3}>
                                                    <Checkbox
                                                        sx={{
                                                            transform: 'scale(1.5)',
                                                            padding: '5px',
                                                        }}
                                                        checked={reviewState.food[item.id]?.recommend === 0}
                                                        onChange={() => reviewState.handleFoodReviewChange(item.id, false)}
                                                        color="primary"
                                                        inputProps={{
                                                            'aria-label': 'secondary checkbox',
                                                        }}
                                                    />
                                                </Box>
                                            </>
                                        )}
                                    </Box>
                                </Box>
                            ))}
                        </Box>
                    </Box>
                    <Box
                        p={3}
                        sx={{
                            color: theme => theme.palette.text.secondary,
                        }}
                    >
                        <Box pb={2}>
                            <DividerWithText>
                                <SmallText semibold>{t('delivery')}</SmallText>
                            </DividerWithText>
                        </Box>
                        <Grid container spacing={3} direction="row" alignItems="center" justifyContent="space-between">
                            {reviewState.review ? (
                                <>
                                    {reviewState?.deliveryDisplay.map(obj => (
                                        <Grid item xs={6}>
                                            <ReviewDisplay reviewOption={obj.value} title={obj.title} />{' '}
                                        </Grid>
                                    ))}
                                    <Grid item xs={6}>
                                        {' '}
                                        <Box display="flex" justifyContent="center">
                                            <SmallText color="textPrimary">
                                                <b>{t('comment', { count: 1 })}: </b>
                                                {reviewState?.review?.foodDelivery?.comment
                                                    ? reviewState.review.foodDelivery.comment
                                                    : '/'}
                                            </SmallText>
                                        </Box>
                                    </Grid>
                                </>
                            ) : (
                                <>
                                    {reviewState?.deliveryToggle &&
                                        reviewState.deliveryToggle.map(obj => (
                                            <Grid item xs={6}>
                                                <VoteToggler
                                                    value={reviewState?.delivery[obj.key]}
                                                    title={obj.title}
                                                    onChange={value => reviewState.handleChoice(obj.type, obj.key, value)}
                                                />
                                            </Grid>
                                        ))}
                                    <Grid item xs={6}>
                                        <Box display="flex" flexDirection="column" alignItems="center">
                                            <SmallText semibold color="textPrimary">
                                                {t('leaveAComment')}
                                            </SmallText>
                                            <Box p={1}>
                                                <TextField
                                                    id="outlined-basic"
                                                    label={t('leaveAComment')}
                                                    variant="outlined"
                                                    value={reviewState?.delivery.comment}
                                                    onChange={e => reviewState.handleCommentChange(e, 'delivery')}
                                                    fullWidth
                                                    size="small"
                                                />
                                            </Box>
                                        </Box>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </Box>
                    {!reviewState?.review && (
                        <Box p={2} display="flex" justifyContent="center">
                            <Box width="200px">
                                {' '}
                                <PrimaryButton fullWidth onClick={onSave}>
                                    {t('save')}
                                </PrimaryButton>
                            </Box>
                        </Box>
                    )}
                </Box>
            </Box>
        </div>
    );
};

export default ReviewModal;
