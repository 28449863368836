import { Box } from '@mui/material';
import { useJsApiLoader } from '@react-google-maps/api';
import { Fragment, ReactNode, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setIsMapLoaded, setLoadError } from 'redux/actions/internal/general/map';

interface IGoogleMapsApiContainerProps {
    children: ReactNode;
}

type Libraries = ('drawing' | 'geometry' | 'localContext' | 'places' | 'visualization')[];
const libraries: Libraries = ['places', 'geometry'];

const GoogleMapsApiContainer = ({ children }: IGoogleMapsApiContainerProps) => {
    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: 'AIzaSyAfs7o61AFwX4VD9pyAdjWi2Z1dLuNIcWQ',
        libraries,
    });

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setIsMapLoaded(isLoaded));
    }, [isLoaded]);

    useEffect(() => {
        dispatch(setLoadError(loadError));
    }, [loadError]);

    return <Fragment>{children}</Fragment>;
};

export default GoogleMapsApiContainer;
