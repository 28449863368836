import { useDispatch, useSelector } from 'react-redux';
import {
    hideLoginModal,
    showLoginModal,
    showLoginModalRegisterStep,
} from 'redux/actions/internal/general/global';
import { RootState } from 'redux/reducers';

export const useLoginFlow = () => {
    const dispatch = useDispatch();
    const { isLoginOpen, isRegisterOpen } = useSelector((state: RootState) => state.globals.data);
    return {
        openLogin: () => {
            dispatch(showLoginModal());
        },
        showRegister: () => {
            dispatch(showLoginModalRegisterStep());
        },
        closeLogin: () => {
            dispatch(hideLoginModal());
        },
        isLoginOpen,
        isRegisterOpen,
    };
};
