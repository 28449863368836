import { Box, DialogContentText } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { MediumText, NormalText } from 'components/atoms/Typography';
import { useStreamline } from 'hooks/useStreamline';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import { DialogActionType, DialogInfo, DialogState } from 'redux/types/internalTypes';

export default function AlertDialog() {
    const dialogInfo = useSelector((state: RootState) => state.dialogInfo.data);
    const [openedInfo, setOpenedInfo] = useState<DialogInfo>(null);
    const [openedType, setOpenedType] = useState<keyof DialogState>();
    const { updateDialogInfo } = useStreamline();

    useEffect(() => {
        Object.entries(dialogInfo).forEach(([type, info]: [keyof DialogState, DialogInfo]) => {
            if (info.alertVisible) {
                setOpenedInfo(info);
                setOpenedType(type);
            }
        });
        if (Object.values(dialogInfo).every((info: DialogInfo) => !info.alertVisible)) {
            setOpenedInfo(null);
        }
    }, [dialogInfo]);

    const handleClose = () => {
        updateDialogInfo({ [openedType]: { alertVisible: false } });
    };

    const handleSuccess = () => {
        updateDialogInfo({
            [openedType]: { ...openedInfo, alertVisible: false, action: 'success' as DialogActionType },
        });
    };

    if (!openedInfo) return null;

    return (
        <div>
            <Dialog
                open={openedInfo?.alertVisible || false}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
            >
                {!openedInfo && null}
                {openedInfo && (
                    <>
                        <DialogTitle id="alert-dialog-title">
                            <MediumText semibold>{openedInfo?.title}</MediumText>
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">{openedInfo?.description}</DialogContentText>
                            {openedInfo?.illustration && (
                                <Box display="flex" justifyContent="center" mb={2}>
                                    <img src={openedInfo.illustration} style={{ width: '300px' }} />
                                </Box>
                            )}
                        </DialogContent>
                        <DialogActions>
                            {openedInfo?.cancelable && (
                                <Box mr={2}>
                                    <Button
                                        onClick={handleClose}
                                        color={openedInfo?.cancelColor}
                                        variant="contained"
                                        sx={{ textTransform: 'none', color: 'white' }}
                                    >
                                        <NormalText>{openedInfo?.cancelText}</NormalText>
                                    </Button>
                                </Box>
                            )}
                            <Button
                                onClick={handleSuccess}
                                variant="contained"
                                color={openedInfo?.successColor}
                                sx={{ textTransform: 'none', color: 'white' }}
                            >
                                <NormalText>{openedInfo?.successText}</NormalText>
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>
        </div>
    );
}
