import { Box } from '@mui/material';
import { useTheme } from '@mui/system';
import { BaseButton, ButtonsCompound } from 'components/atoms/Buttons';
import { SmallText } from 'components/atoms/Typography';
import useTranslation from 'next-translate/useTranslation';

interface IVoteTogglerProps {
    title?: string;
    value?: string | number;
    onChange?: (value: boolean) => void;
}

const VoteToggler = ({ title, value, onChange }: IVoteTogglerProps) => {
    const { t } = useTranslation('common');
    const handleToggle = value => {
        if (onChange) {
            onChange(value);
        }
    };
    const theme = useTheme();

    return (
        <div>
            <Box display="flex" flexDirection="column" alignItems="center">
                <SmallText semibold color="textPrimary">
                    {title}
                </SmallText>
                <Box p={1}>
                    <ButtonsCompound variant="secondary">
                        <BaseButton
                            variant="secondary"
                            onClick={() => handleToggle(true)}
                            sx={
                                value === 1
                                    ? {
                                          color: 'white',
                                          backgroundColor: theme.palette.primary.main,
                                          border: 'none',
                                          '&:hover': {
                                              backgroundColor: theme.palette.primary.main,
                                          },
                                      }
                                    : { border: 'none' }
                            }
                        >
                            {t('yes')}
                        </BaseButton>
                        <BaseButton
                            variant="secondary"
                            onClick={() => handleToggle(false)}
                            sx={
                                value === 0
                                    ? {
                                          color: 'white',
                                          backgroundColor: theme.palette.primary.main,
                                          border: 'none',
                                          '&:hover': {
                                              backgroundColor: theme.palette.primary.main,
                                          },
                                      }
                                    : { border: 'none' }
                            }
                        >
                            {t('no')}
                        </BaseButton>
                    </ButtonsCompound>
                </Box>
            </Box>
        </div>
    );
};

export default VoteToggler;
