import { Box } from '@mui/material';
import { isEqual, lowerCase } from 'lodash';
import useTranslation from 'next-translate/useTranslation';
import { ChangeEvent, useEffect, useState } from 'react';
import Autocomplete from 'react-autocomplete';
import { AutocompleteService, GeocodeCallback, GeocoderService, GetPlacePredictionsCallback } from 'services/mapService';
import { Extend } from 'types/global';
import locationVector from 'assets/svg/locationVector.svg';
import { MediumText } from 'components/atoms/Typography';

interface IKlikAutocompleteProps {
    address: {
        fullAddress: string;
        longitude: number;
        latitude: number;
    };
    onAddressChange?: (address: {
        fullAddress: string;
        latitude: number;
        longitude: number;
        city: string;
        streetNumber?: string;
    }) => void;
    placeholder?: string;
    landing?: boolean;
    onSetCurrentLocation?: () => void;
    onCityChange?: (city: string) => void;
}

interface Option {
    value: string;
    title: string;
    data: google.maps.places.AutocompletePrediction;
}

const KlikAutocomplete = ({
    address,
    onAddressChange,
    placeholder,
    landing,
    onSetCurrentLocation,
    onCityChange,
}: IKlikAutocompleteProps) => {
    const [value, setValue] = useState<string>('');
    const { t } = useTranslation('common');
    const [options, setOptions] = useState<Option[]>([]);

    const autocompleteService = new AutocompleteService();
    const geocoderService = new GeocoderService();

    useEffect(() => {
        setValue(address.fullAddress);
        if (value)
            try {
                autocompleteService.getPlacePredictions(value, handlePredictions);
            } catch (e) {
                console.log('error', e);
            }
    }, [address?.fullAddress, address?.longitude, address?.latitude]);

    const handleInputChange = (event: ChangeEvent, value: string) => {
        setValue(value);
        if (value) autocompleteService.getPlacePredictions(value, handlePredictions);
        else setOptions([]);
    };

    const handleValueChange = (event: ChangeEvent, value: Option) => {
        geocoderService.geocode(
            { placeId: value.data.place_id },
            (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
                handleGeocoding(results, status, value);
            }
        );
    };

    const handlePredictions: GetPlacePredictionsCallback = results => {
        if (results) {
            setOptions(
                results.map(item => ({
                    value: item.place_id,
                    title: item.description,
                    data: item,
                }))
            );
        }
    };

    const handleGeocoding: Extend<GeocodeCallback, Option> = (results, status, value) => {
        if (status === 'OK') {
            if (results[0]) {
                if (onAddressChange) {
                    const city = results[0].address_components.find(el => isEqual(el.types, ['locality', 'political']));
                    const streetNumber = results[0].address_components.find(el =>
                        isEqual(el.types, ['street_number'])
                    )?.short_name;

                    onAddressChange({
                        fullAddress: value.data.description,
                        latitude: results[0].geometry.location.lat(),
                        longitude: results[0].geometry.location.lng(),
                        city: city?.long_name?.toLocaleLowerCase(),
                        streetNumber: streetNumber,
                    });
                    onCityChange?.(city?.long_name?.toLocaleLowerCase() || city?.long_name?.toLocaleLowerCase());
                }
            } else {
                window.alert(t('noResultsFound'));
            }
        } else {
            window.alert('Geocoder failed due to: ' + status);
        }
    };

    return (
        <Box
            display="flex"
            borderRadius={landing && '8px'}
            border={landing && '1px solid #C4C4C4'}
            alignItems="center"
            p={landing ? 1 : 0}
            width={landing && '450px'}
            fontSize={{ xs: '16px', md: '12px' }}
            sx={{ backgroundColor: landing && 'white', position: 'relative' }}
            zIndex={999}
        >
            {landing && (
                <img
                    src={locationVector}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        onSetCurrentLocation?.();
                        setValue(address.fullAddress);
                    }}
                />
            )}
            <Autocomplete
                getItemValue={item => item.title}
                items={options}
                renderItem={(item, isHighlighted) => (
                    <div
                        key={item.title}
                        style={{ background: isHighlighted ? 'lightgray' : 'white', fontSize: landing && '1.8em' }}
                        className={landing ? 'address-autocomplete-rendered-item-landing' : 'address-autocomplete-rendered-item'}
                    >
                        {item.title}
                    </div>
                )}
                // renderMenu={(items, value) => (
                //     <Box display="flex" flexDirection="column">
                //         <MediumText>Use my current location</MediumText>
                //         {items}
                //     </Box>
                // )}
                value={value}
                onChange={e => handleInputChange(e, e.target.value)}
                onSelect={(val, item) => handleValueChange(null, item)}
                wrapperProps={{ className: 'address-autocomplete-wrapper' }}
                menuStyle={{
                    zIndex: 99,
                    borderRadius: '8px',
                    boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                    background: 'rgba(255, 255, 255, 0.9)',
                    marginTop: '12px',
                    fontSize: '1em',
                    position: 'fixed',
                    overflow: 'auto',
                    maxHeight: '50%',
                    // marginLeft: '-27px',

                    width: landing && '450px',
                }}
                inputProps={{
                    placeholder: placeholder || `${t('insertStreet')}...`,
                    style: {
                        fontSize: landing ? '1.8em' : '1em',
                        border: landing && 'none',
                        outline: landing && 'none',
                    },
                }}
            />
        </Box>
    );
};

export default KlikAutocomplete;
