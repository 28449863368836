export type GetPlacePredictionsCallback = (
    results: google.maps.places.AutocompletePrediction[],
    status: google.maps.places.PlacesServiceStatus
) => void;

export type GeocodeCallback = (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => void;

export class AutocompleteService {
    service: google.maps.places.AutocompleteService;
    locationBias: google.maps.LatLng;
    constructor() {
        this.service = new google.maps.places.AutocompleteService();
        this.locationBias = new google.maps.LatLng(41.8652763, 21.9214045);
    }
    getPlacePredictions(value: string, callback: GetPlacePredictionsCallback) {
        this.service.getPlacePredictions(
            {
                input: value,
                bounds: new google.maps.LatLngBounds(
                    new google.maps.LatLng(40.866667, 20.459167),
                    new google.maps.LatLng(42.373056, 23.033333)
                ),
                location: this.locationBias,
                // origin: this.locationBias,
                radius: 10000,
            },
            callback
        );
    }
    setLocationBias(latitude: number, longitude: number) {
        this.locationBias = new google.maps.LatLng(latitude, longitude);
    }
}

export class GeocoderService {
    service: google.maps.Geocoder;
    constructor() {
        this.service = new google.maps.Geocoder();
    }
    geocode(request: google.maps.GeocoderRequest, callback: GeocodeCallback) {
        this.service.geocode(request, callback);
    }
}
