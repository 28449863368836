import firebase from 'config/firebase';

export const getDetailsUrl = (id: string, name: string) => {
    return `/details/${name.split(' ').join('-')}?id=${id}`;
};

export const getDetailsRouterArguments = (id: string, name: string, additionalQueries?: any) => {
    return {
        pathname: `/details/[name]`,
        query: {
            name: name.split(' ').join('-'),
            id,
            ...additionalQueries,
        },
    };
};

export const getImageUrl = (ref: string) => {
    const storage = firebase.storage();
    return storage.ref(ref).getDownloadURL();
};
