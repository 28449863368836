import { Box } from '@mui/material';
import popupTriangle from 'assets/svg/popup-triangle.svg';

interface IHeaderPopupProps {
    children?: any;
    visible?: boolean;
    width?: string;
}

const HeaderPopup = ({ children, visible, width }: IHeaderPopupProps) => {
    return (
        <div>
            <Box
                sx={{
                    position: 'absolute',
                    right: '-10px',
                    py: 3,
                }}
            >
                {visible && (
                    <Box
                        sx={{
                            backgroundColor: 'white',
                            position: 'relative',
                            borderRadius: '10px',
                            boxShadow: '0px 30px 60px rgba(0, 0, 0, 0.25)',
                            maxHeight: visible ? 'calc(95vh - 100px)' : '0',
                        }}
                        width={width || '230px'}
                        display="flex"
                        flexDirection="column"
                    >
                        <Box
                            component="img"
                            sx={{ position: 'absolute', top: '-15px', right: '5px', zIndex: -1 }}
                            src={popupTriangle}
                        />
                        {children}
                    </Box>
                )}
            </Box>
        </div>
    );
};

export default HeaderPopup;
