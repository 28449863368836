import firebase from 'config/firebase';
import { useFirestore } from './useFirestore';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { Review } from 'klikni-jadi-shared-stuff';
import { CartItems } from 'klikni-jadi-shared-stuff';

interface OrderReviews {
    restaurant: ReviewType;
    food: foodReview;
    delivery: ReviewType;
}
type ReviewType = Record<string, string | number>;

interface foodReview {
    [foodItemId: string]: {
        recommend: number;
    };
}
interface DisplayType {
    title: string;
    value: number;
}
const initialRestaurantReviews = {
    delicious: -1,
    cheap: -1,
    largePortions: -1,
    authenticity: -1,
    orderAgain: -1,
    comment: '',
};

const initialDeliveryReviews = {
    polite: -1,
    onTime: -1,
    doorToDoor: -1,
    contacted: -1,
    changeBack: -1,
    comment: '',
};

interface ToggleType {
    title: string;
    type: string;
    key: string;
    value: string | number;
}
const initialPolite = {
    title: 'Polite?',
    type: 'delivery',
    key: 'polite',
    value: -1,
};
const initialOnTime = {
    title: 'On time?',
    type: 'delivery',
    key: 'onTime',
    value: -1,
};
const initialDoorToDoor = {
    title: 'Door to door delivery?',
    type: 'delivery',
    key: 'doorToDoor',
    value: -1,
};
const initialContacted = {
    title: 'Were you contacted?',
    type: 'delivery',
    key: 'contacted',
    value: -1,
};
const initialChangeBack = {
    title: 'Change back?',
    type: 'delivery',
    key: 'changeBack',
    value: -1,
};
const initialOrderAgain = {
    title: 'Would you order again?',
    type: 'restaurant',
    key: 'orderAgain',
    value: -1,
};
const initialAuthenticity = {
    title: 'Authenticity?',
    type: 'restaurant',
    key: 'authenticity',
    value: -1,
};
const initialLargePortions = {
    title: 'Large portions?',
    type: 'restaurant',
    key: 'largePortions',
    value: -1,
};
const initialCheap = {
    title: 'Cheap?',
    type: 'restaurant',
    key: 'cheap',
    value: -1,
};
const initialDelicious = {
    title: 'Delicious?',
    type: 'restaurant',
    key: 'delicious',
    value: -1,
};
const initialReviewOrderAgain = {
    title: 'Would you order again?',
    value: -1,
};
const initialReviewAuthenticity = {
    title: 'Authenticity?',
    value: -1,
};
const initialReviewLargePortions = {
    title: 'Large portions?',
    value: -1,
};
const initialReviewCheap = {
    title: 'Cheap?',
    value: -1,
};
const initialReviewDelicious = {
    title: 'Delicious?',
    value: -1,
};
const initialReviewPolite = {
    title: 'Polite?',
    value: -1,
};
const initialReviewOnTime = {
    title: 'On time?',
    value: -1,
};
const initialReviewDoorToDoor = {
    title: 'Door to door delivery?',
    value: -1,
};
const initialReviewContacted = {
    title: 'Were you contacted?',
    value: -1,
};
const initialReviewChangeBack = {
    title: 'Change back?',
    value: -1,
};

export const useReviews = (items?: CartItems, review?: Review) => {
    const [reviews, setReviews] = useState<OrderReviews>({
        restaurant: { ...initialRestaurantReviews },
        delivery: { ...initialDeliveryReviews },
        food: _.values(items).reduce((acc, i) => ({ ...acc, [i.id]: { recommend: -1 } }), {}),
    });
    const [restaurantToggle, setRestaurantToggle] = useState<ToggleType[]>([
        { ...initialDelicious },
        { ...initialCheap },
        { ...initialLargePortions },
        { ...initialAuthenticity },
        { ...initialOrderAgain },
    ]);
    const [deliveryToggle, setDeliveryToggle] = useState<ToggleType[]>([
        { ...initialPolite },
        { ...initialOnTime },
        { ...initialDoorToDoor },
        { ...initialContacted },
        { ...initialChangeBack },
    ]);
    const [restaurantDisplay, setRestaurantDisplay] = useState<DisplayType[]>([
        { ...initialReviewDelicious, value: review?.restaurantReview?.delicious },
        { ...initialReviewCheap, value: review?.restaurantReview?.cheap },
        { ...initialReviewLargePortions, value: review?.restaurantReview?.largePortions },
        { ...initialReviewAuthenticity, value: review?.restaurantReview?.authenticity },
        { ...initialReviewOrderAgain, value: review?.restaurantReview?.orderAgain },
    ]);
    const [deliveryDisplay, setDeliveryDisplay] = useState<DisplayType[]>([
        { ...initialReviewPolite, value: review?.foodDelivery?.polite },
        { ...initialReviewOnTime, value: review?.foodDelivery?.onTime },
        { ...initialReviewDoorToDoor, value: review?.foodDelivery?.doorToDoor },
        { ...initialReviewContacted, value: review?.foodDelivery?.contacted },
        { ...initialReviewChangeBack, value: review?.foodDelivery?.changeBack },
    ]);
    const [reviewsArray, setReviewsArray] = useState<[string, { recommend: number }][]>(null);
    const reviewRef = useFirestore('reviews');
    useEffect(() => {
        if (review) {
            setReviewsArray(Object.entries(review.foodItemReview));
            setRestaurantDisplay([
                { ...initialReviewDelicious, value: review?.restaurantReview?.delicious },
                { ...initialReviewCheap, value: review?.restaurantReview?.cheap },
                { ...initialReviewLargePortions, value: review?.restaurantReview?.largePortions },
                { ...initialReviewAuthenticity, value: review?.restaurantReview?.authenticity },
                { ...initialReviewOrderAgain, value: review?.restaurantReview?.orderAgain },
            ]);
            setDeliveryDisplay([
                { ...initialReviewPolite, value: review?.foodDelivery?.polite },
                { ...initialReviewOnTime, value: review?.foodDelivery?.onTime },
                { ...initialReviewDoorToDoor, value: review?.foodDelivery?.doorToDoor },
                { ...initialReviewContacted, value: review?.foodDelivery?.contacted },
                { ...initialReviewChangeBack, value: review?.foodDelivery?.changeBack },
            ]);
        } else {
            setReviewsArray([]);
            setRestaurantDisplay([
                { ...initialReviewDelicious },
                { ...initialReviewCheap },
                { ...initialReviewLargePortions },
                { ...initialReviewAuthenticity },
                { ...initialReviewOrderAgain },
            ]);
            setDeliveryDisplay([
                { ...initialReviewPolite },
                { ...initialReviewOnTime },
                { ...initialReviewDoorToDoor },
                { ...initialReviewContacted },
                { ...initialReviewChangeBack },
            ]);
        }
    }, [review]);

    return {
        restaurant: reviews.restaurant,
        delivery: reviews?.delivery,
        food: reviews.food,
        reviews: reviews,
        restaurantToggle: restaurantToggle,
        deliveryToggle: deliveryToggle,
        restaurantDisplay: restaurantDisplay,
        deliveryDisplay: deliveryDisplay,
        reviewsArray: reviewsArray,
        review: review,
        items: items,
        saveToFirestore: (orderId, restaurantId, foodMenuId, currentUserId, currentUserName) => {
            const review = {
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                orderId: orderId,
                restaurantId: restaurantId,
                foodMenuId: foodMenuId,
                foodDelivery: {
                    ..._.pickBy(reviews?.delivery, val => val != -1),
                },

                foodItemReview: {
                    ..._.pickBy(reviews.food, val => val.recommend >= 0),
                },

                restaurantReview: {
                    ..._.pickBy(reviews.restaurant, val => val != -1),
                },
                customer: {
                    id: currentUserId,
                    name: currentUserName,
                    // photo: currentUser.data.
                },
            };

            if (!review.foodMenuId) {
                delete review.foodMenuId;
            }

            reviewRef.create(review);
        },

        handleChoice: (type: string, key: string, value: string | boolean) => {
            let newValue = null;
            if (typeof value === 'string') {
                newValue = value;
            } else {
                if (value) {
                    if (reviews[type][key] === 1) {
                        newValue = -1;
                    } else {
                        newValue = 1;
                    }
                } else {
                    if (reviews[type][key] === 0) {
                        newValue = -1;
                    } else {
                        newValue = 0;
                    }
                }
            }
            if (type === 'food') {
                setReviews({
                    ...reviews,
                    [type]: {
                        ...reviews[type],
                        [key]: { recommend: newValue },
                    },
                });
            } else {
                setReviews({
                    ...reviews,
                    [type]: {
                        ...reviews[type],
                        [key]: newValue,
                    },
                });
            }
        },
        handleFoodReviewChange: (key: string, value: boolean) => {
            let newValue = null;
            if (value) {
                if (reviews['food'][key].recommend === 1) {
                    newValue = -1;
                } else {
                    newValue = 1;
                }
            } else {
                if (reviews['food'][key].recommend === 0) {
                    newValue = -1;
                } else {
                    newValue = 0;
                }
            }
            setReviews({
                ...reviews,
                ['food']: {
                    ...reviews['food'],
                    [key]: { recommend: newValue },
                },
            });
        },
        handleCommentChange: (e, type) => {
            const value = e.target.value;
            let newValue = null;
            newValue = value;

            setReviews({
                ...reviews,
                [type]: {
                    ...reviews[type],
                    ['comment']: newValue,
                },
            });
        },
    };
};
