import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import shoppingBag from 'assets/svg/shopping-bag.svg';
import shoppingBagWhite from 'assets/svg/shopping-bag-white.svg';
import Link from 'next/link';
import theme from 'core/theme';
import { forEach } from 'lodash';
import { useEffect, useState } from 'react';
import { SmallText } from './atoms/Typography';
import { useScrollerContext } from 'context/scroller-context';

interface IShoppingBagProps {
    transparentSearch: boolean;
}

const ShoppingBag = ({ transparentSearch }: IShoppingBagProps) => {
    const userCart = useSelector((state: RootState) => state.userCart).data;
    const [cartItemsQuantity, setCartItemsQuantity] = useState<number>(0);

    useEffect(() => {
        let itemsQuantity = 0;
        forEach(userCart.groupedItems, groupedItem => forEach(groupedItem.items, item => (itemsQuantity += item.quantity)));

        setCartItemsQuantity(itemsQuantity);
    }, [userCart]);

    const { data } = useScrollerContext();

    const isHeaderTransparentZone = data.isHeaderTransparent;

    return (
        <Box mr={3} position="relative" ml={{ xs: 1, sm: 0 }}>
            {/* <InsideComponent onOutsideClick={handleOutsideClick}> */}
            <Link href="/cart" as="/cart">
                <a>
                    <Box>
                        <Box sx={{ cursor: 'pointer' }}>
                            {(transparentSearch && isHeaderTransparentZone && <img src={shoppingBagWhite} />) || (
                                <img src={shoppingBag} alt="shopping bag" aria-label="User cart" />
                            )}
                        </Box>
                        {/* <CartPopup /> */}
                        {!userCart.isEmpty && (
                            <Box
                                sx={{
                                    background: theme.palette.primary.main,
                                    width: '20px',
                                    height: '20px',
                                    borderRadius: '100%',
                                    position: 'absolute',
                                    top: '-7px',
                                    right: '-7px',
                                }}
                                textAlign="center"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                            >
                                <SmallText
                                    sx={{
                                        color: 'white',
                                        display: 'inline-block',
                                    }}
                                >
                                    {cartItemsQuantity}
                                </SmallText>
                            </Box>
                        )}
                    </Box>
                </a>
            </Link>
            {/* </InsideComponent> */}
        </Box>
    );
};

export default ShoppingBag;
