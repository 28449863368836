import { Autocomplete, Box, BoxProps, Divider, Paper, styled, TextField, Theme } from '@mui/material';

import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import currentLocation from 'assets/svg/currentLocation.svg';
import currentLocationFull from 'assets/svg/currentLocationFull.svg';
import locationVector from 'assets/svg/locationVector.svg';
import useTranslation from 'next-translate/useTranslation';
import { AutocompleteService, GeocodeCallback, GeocoderService, GetPlacePredictionsCallback } from 'services/mapService';
import { Extend } from 'types/global';
import { forEach, isEqual } from 'lodash';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import styles from 'styles/search.module.css';
import UserAddressMap from './UserAddressMap';
import { CustomerAddress, Region } from 'klikni-jadi-shared-stuff';
import { useFirestore } from 'hooks/useFirestore';
import { useRouting } from 'services/routing';
import { collection } from 'services/db/firestoreApi';
import { useCartService } from 'services/cart/useCartService';
import { useLocationContext } from 'context/current-location';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { randomInt } from 'crypto';

interface ILandingSearchProps {
    address: {
        fullAddress: string;
        longitude: number;
        latitude: number;
    };
    onAddressChange?: (address: { fullAddress: string; latitude: number; longitude: number }) => void;
    placeholder?: string;
    onGetCurrentLocation?: (latitude, longitude, fullAddress, city) => void;
    onSetCurrentLocation?: () => void;
    locationDenied?: boolean;
    paperWidth?: string;
    onCloseModal?: () => void;
    addAddressToHost?: (address) => void;
    textColor?: string;
}
interface Option {
    value: string;
    title: string;
    type?: string;
    data: google.maps.places.AutocompletePrediction;
}
interface SearchWrapperStyledProps extends BoxProps {
    theme?: Theme;
}
const SearchWrapperStyled = styled(Box)(({ theme }: SearchWrapperStyledProps) => ({
    transition: theme.transitions.create(['max-width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.complex,
    }),
    overflow: 'hidden',
}));

const NewLandingSearch = ({
    address,
    onAddressChange,
    placeholder,
    onSetCurrentLocation,
    onGetCurrentLocation,
    locationDenied,
    paperWidth,
    onCloseModal,
    addAddressToHost,
    textColor,
}: ILandingSearchProps) => {
    const [optionValue] = useState(null);
    const [value, setValue] = useState<string>('');
    const customersRef = useFirestore('customers');
    const { t } = useTranslation('common');
    const [allUserOptions, setAllUserOptions] = useState<Option[]>([]);
    const [newAddress, setNewAddress] = useState<[string, CustomerAddress]>(null);
    const [toggleHover, setToggleHover] = useState<boolean>(false);
    const user = useSelector((state: RootState) => state.currentUser);
    const [options, setOptions] = useState<Option[]>([]);
    const [allOptions, setAllOptions] = useState<Option[]>([]);
    const [openNewAddress, setOpenNewAddress] = useState<boolean>(false);
    const { update, data: location } = useLocationContext();
    const routing = useRouting();
    const cartService = useCartService();
    const icon = useMemo(() => {
        if (toggleHover) return currentLocation;
        else return currentLocationFull;
    }, [toggleHover]);

    const autocompleteService = new AutocompleteService();
    const geocoderService = new GeocoderService();

    // This useEffect sets first option of the list
    // If the user is signed in, it is 'Add new address' - if the user is not signed in, it is 'Use current location'
    useEffect(() => {
        let currLoc;
        let add_new_address;
        let ops = [];
        if (!user?.data) {
            currLoc = {
                value: locationDenied ? t('location_denied') : t('use_current_location'),
                title: locationDenied ? t('location_denied') : t('use_current_location'),
                type: 'useCurrentLocation',
            };
            ops.push(currLoc);
        }
        if (user?.data) {
            add_new_address = { value: t('add_new_address'), title: t('add_new_address'), type: 'addNewAddress' };
            ops.push(add_new_address);
        }

        if (options?.length > 0) {
            ops.push(...options);
        }

        setAllOptions(ops);
    }, [options, locationDenied, user?.data]);

    // sets value when outside address changes -> autocomplete predictions
    useEffect(() => {
        if (address?.fullAddress) {
            setValue(address?.fullAddress);
            if (value && !user?.data)
                try {
                    autocompleteService.getPlacePredictions(value, handlePredictions);
                } catch (e) {
                    console.log('error', e);
                }
        }
    }, [address?.fullAddress, address?.longitude, address?.latitude]);

    // when user changes, it adds user options to the autocomplete list - If they are logged in
    // If no user, it gets current location
    useEffect(() => {
        if (user?.data) {
            if (user.data?.addresses) {
                const userOptions = [];

                forEach(user.data?.addresses, (address, key) => {
                    userOptions.push({
                        value: address.fullAddress,
                        title: address.fullAddress,
                        data: { ...address, id: key },
                    });
                });
                setOptions(userOptions);
                setAllUserOptions(userOptions);
            }
        } else {
            if (!addAddressToHost) {
                navigator.geolocation.getCurrentPosition(successCallback, errorCallback, {
                    timeout: 5000,
                    enableHighAccuracy: true,
                });
            }
        }
    }, [user]);

    // success callback of the get current location
    const successCallback = position => {
        const { latitude, longitude } = position.coords;

        geocoderService.geocode(
            { location: { lat: latitude, lng: longitude } },
            (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
                if (results.length) {
                    let found = results.find(add => add.types.includes('street_address') || add.types.includes('route'));
                    if (!found) {
                        found = results[0];
                    }
                    const city = found.address_components.find(el => isEqual(el.types, ['locality', 'political']));
                    onGetCurrentLocation(latitude, longitude, found.formatted_address, city);
                }
            }
        );
    };
    const errorCallback = e => {
        console.log(e);
    };

    // onInputChange of the autocomplete
    const handleInputChange = (value: string) => {
        if (value) {
            if (user?.data) {
                const filtered = allUserOptions.filter(o => o.title.toLowerCase().includes(value.toLocaleLowerCase()));

                setOptions(filtered);
            } else autocompleteService.getPlacePredictions(value, handlePredictions);
        } else if (!user.data) {
            setOptions([]);
        }
    };

    // autocompleteService.getPlacePredictions - success callback
    const handlePredictions: GetPlacePredictionsCallback = results => {
        if (results) {
            setOptions(
                results.map(item => ({
                    value: item.place_id,
                    title: item.description,
                    data: item,
                }))
            );
        }
    };

    // geocoderService.geocode - success callback
    const handleGeocoding: Extend<GeocodeCallback, Option> = (results, status, value) => {
        if (status === 'OK') {
            if (results[0]) {
                if (onAddressChange) {
                    onAddressChange({
                        fullAddress: value.data.description,
                        latitude: results[0].geometry.location.lat(),
                        longitude: results[0].geometry.location.lng(),
                    });
                }
            } else {
                window.alert(t('noResultsFound'));
            }
        } else {
            window.alert('Geocoder failed due to: ' + status);
        }
    };

    // onChange of the autocomplete
    const handleValueChange = (value: Option) => {
        if (value?.type) {
            if (value.type === 'addNewAddress') {
                setOpenNewAddress(true);
            } else if (value.type === 'useCurrentLocation') {
                if (locationDenied) {
                    navigator.geolocation.getCurrentPosition(successCallback, errorCallback, {
                        timeout: 5000,
                        enableHighAccuracy: true,
                    });
                } else {
                    onSetCurrentLocation?.();
                }

                // setValue(address?.fullAddress);
            }
        } else {
            if (user?.data) {
                if (addAddressToHost) {
                    addAddressToHost?.(value.data);
                } else navigateToListings(value.data);
            } else
                geocoderService.geocode(
                    { placeId: value.data.place_id },
                    (results: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
                        handleGeocoding(results, status, value);
                    }
                );
        }
    };

    // UserAddressMap - onSave
    const handleAddressSave = async (address: [string, CustomerAddress]) => {
        setOpenNewAddress(false);
        address[1].approved = false;
        const newAddressId = customersRef.id();
        const locations = user.data.addresses;
        locations[newAddressId] = address[1];
        if (user.data) {
            customersRef
                .set(user.data.id, {
                    addresses: locations,
                })
                .then(() => {
                    if (addAddressToHost) {
                        addAddressToHost?.({ ...address[1], id: newAddressId });
                    } else {
                        navigateToListings({ ...address[1], id: newAddressId });
                    }
                });
        }
    };

    const navigateToListings = address => {
        setValue(address?.fullAddress);
        collection<Region>('configuration/local/regions').then(regions => {
            let found;
            for (const region of regions) {
                if (
                    region?.coordinates &&
                    google.maps.geometry.poly.containsLocation(
                        { lat: address.location.latitude, lng: address?.location.longitude },
                        new google.maps.Polygon({ paths: region.coordinates })
                    )
                ) {
                    found = region?.id;
                }
            }

            cartService.addAddressToHost({ ...address }).then(() => {
                update('__data__', {
                    location: {
                        latitude: address.location.latitude,
                        longitude: address.location.longitude,
                        fullAddress: address.fullAddress,
                    },
                    region: found,
                    addressId: address.id,
                });
                onCloseModal?.();
                routing.push({
                    to: '/partners/discover',
                    queries: {
                        ...(address.location.latitude
                            ? {
                                  nearby: `${[address.location?.latitude, address.location?.longitude].join(',')}`,
                                  city: found,
                                  a: address.id,
                              }
                            : {}),
                    },
                });
            });
        });
    };

    // handle click on the autocomplete wrapper
    const handleClick = () => {
        if (!user.data) {
            if (!addAddressToHost) {
                navigator.geolocation.getCurrentPosition(successCallback, errorCallback, {
                    timeout: 5000,
                    enableHighAccuracy: true,
                });
            }
        }
    };

    return (
        <Box position="relative">
            <SearchWrapperStyled
                display="flex"
                alignItems="center"
                justifyContent="center"
                width={paperWidth ? paperWidth : { xs: '300px', sm: '300px', md: '350px' }}
                flex={1}
                onClick={handleClick}
            >
                <Autocomplete
                    id="auto-search"
                    sx={{ width: '100%' }}
                    // key={allOptions.length}
                    options={allOptions}
                    filterOptions={options => options}
                    getOptionLabel={option => option?.title || ''}
                    disableClearable
                    value={optionValue}
                    inputValue={value}
                    freeSolo
                    onChange={(event, newValue: any) => {
                        if (newValue !== undefined && newValue !== null) {
                            if (typeof newValue === 'string') {
                                // setValue(newValue);
                            } else {
                                handleValueChange(newValue);
                            }
                        }
                    }}
                    onInputChange={(event, newInputValue) => {
                        setValue(newInputValue);
                        handleInputChange(newInputValue);
                    }}
                    renderInput={params => (
                        <Box
                            display="flex"
                            sx={{
                                backgroundColor: 'white',
                                border: `2px solid rgba(32, 33, 37, 0.12)`,
                                p: 1.5,
                                borderRadius: '0.5rem',
                                '&:has(input:focus)': {
                                    borderColor: 'primary.main',
                                    borderStyle: 'solid',
                                    borderWidth: 2,
                                },
                            }}
                            flexDirection="row"
                            alignItems="center"
                            // key={router.asPath}
                        >
                            <img src={locationVector} style={{ width: 20 }} />
                            <Box flexGrow={1} ml={1} alignItems="center">
                                <TextField
                                    {...params}
                                    className={styles['klik-search-input']}
                                    placeholder={placeholder}
                                    size="small"
                                    sx={{
                                        padding: 0,
                                        border: 'none',
                                        fontSize: { xs: '16px', md: '1em' },
                                    }}
                                    autoFocus
                                    InputProps={{
                                        ...params.InputProps,
                                        style: {
                                            padding: 0,
                                            minWidth: 0,
                                            color: textColor,
                                        },
                                        sx: {
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderStyle: 'none',
                                            },
                                        },
                                    }}
                                    inputProps={{
                                        ...params.inputProps,
                                        style: {
                                            padding: 0,
                                        },
                                    }}
                                />
                            </Box>
                        </Box>
                    )}
                    renderOption={(props, option) => {
                        return (
                            <li key={option.value} {...props}>
                                <Box display="flex" flexDirection="column" width="100%">
                                    <Box
                                        // sx={{
                                        //     ':hover': {
                                        //         color: 'primary.main',
                                        //     },
                                        // }}
                                        ml={option?.type ? 0 : 3}
                                        display="flex"
                                        alignItems="center"
                                        fontSize={{ xs: '16px', md: '1em' }}
                                    >
                                        {option?.type === 'useCurrentLocation' && (
                                            <img src={icon} style={{ marginRight: '8px' }} />
                                        )}
                                        {option?.type === 'addNewAddress' && (
                                            <AddCircleOutlineIcon style={{ marginRight: '8px' }} />
                                        )}
                                        {option?.title}
                                    </Box>
                                    {option?.type && options?.length > 0 && (
                                        <Box width="100%" pt={1}>
                                            {' '}
                                            <Divider />
                                        </Box>
                                    )}
                                </Box>
                            </li>
                        );
                    }}
                    PaperComponent={props => (
                        <Paper
                            {...props}
                            sx={{
                                mt: 2.5,
                                width: paperWidth ? paperWidth : { xs: '300px', sm: '300px', md: '350px' },
                                marginLeft: '-41px',
                                minWidth: '250px',
                            }}
                        />
                    )}
                />
            </SearchWrapperStyled>

            <UserAddressMap
                open={openNewAddress}
                address={newAddress}
                onClose={() => setOpenNewAddress(false)}
                onSave={handleAddressSave}
            />
        </Box>
    );
};

export default NewLandingSearch;
