import { Box, CircularProgress, Theme } from '@mui/material';
import Button, { ButtonProps } from '@mui/material/Button';
import { Children, HTMLAttributes, ReactNode } from 'react';
import { addChildrenAttributes } from 'services/reactManipulation';
import { ButtonVariantsLiteral } from 'types/buttons';
import { Caption, ISizedTextProps, SmallText, NormalText, MediumText } from './Typography';
import ToggleButtonLab, { ToggleButtonProps } from '@mui/material/ToggleButton';
import { styled, useTheme } from '@mui/system';
import { StyledComponent } from '@mui/styles';

export interface IButtonProps extends Omit<ButtonProps, 'variant'> {
    solid?: boolean;
    variant?: ButtonVariantsLiteral;
    hoverBackground?: boolean;
    loading?: boolean;
    classNameOverride?: boolean;
    TextComponentProps?: ISizedTextProps;
}

interface IToggleButtonProps extends Omit<ToggleButtonProps, 'variant'> {
    icon?: ReactNode;
}

interface StyledProps extends ButtonProps {
    theme?: Theme;
    hoverBackground?: boolean;
}

const PrimaryButtonStyled = styled(Button)(({ theme, hoverBackground }: StyledProps) => ({
    textTransform: 'none',
    background: theme.palette.primary.main,
    color: 'white',
    borderRadius: '10px',
    padding: theme.spacing(1, 3),
    boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.15)',
    minWidth: 100,
    '&:hover': {
        background: theme.palette.primary.main,
        opacity: 0.9,
    },
    transition: theme.transitions.create(['transform', 'box-shadow'], {
        duration: theme.transitions.duration.short,
    }),
    '&:disabled': {
        background: theme.palette.grey[100],
        boxShadow: 'none',
    },
    ...(hoverBackground ? {} : {}),
}));

const SecondaryButtonStyled = styled(Button)(({ theme, hoverBackground }: StyledProps) => ({
    textTransform: 'none',
    '&:hover': {
        background: 'none',
    },
    color: theme.palette.primary.main,
    border: `1.15px solid #D8D8D8`,
    padding: theme.spacing(1, 3),
    backgroundColor: '#FFFFFF',
    boxSizing: 'border-box',
    borderRadius: '10px',
    ...(hoverBackground
        ? {
              '&:hover': {
                  background: `#f0f0f0`,
              },
          }
        : {}),
}));

const BasicButtonStyled = styled(Button)(() => ({
    textTransform: 'none',
    textDecoration: 'underline',
    '&:hover': {
        textDecoration: 'underline',
        background: 'none',
    },
    padding: 0,
    minWidth: 0,
}));

const ActionButtonStyled = styled(Button)(({ theme }: StyledProps) => ({
    textTransform: 'none',
    color: theme.palette.primary.main,
    boxSizing: 'border-box',
    background: theme.palette.secondary.main,
    borderRadius: 5,
    '&:hover': {
        background: theme.palette.secondary.main,
    },
    '&:disabled': {
        background: theme.palette.grey[100],
    },
}));

// const ToggleButtonStyled = styled(Button)(({ theme }: StyledProps) => ({
//     border: `1px solid ${theme.palette.grey[100]}`,
//     textTransform: 'none',
//     borderRadius: '5px',
//     color: '#555555',
//     padding: theme.spacing(1, 2),
//     outline: 'none!important',
//     '&.Mui-selected': {
//         backgroundColor: theme.palette.secondary.main,
//         border: `1px solid ${theme.palette.secondary.main}`,
//         color: 'white',
//         '&:hover': {
//             backgroundColor: theme.palette.secondary.main,
//             border: `1px solid ${theme.palette.secondary.main}`,
//             color: 'white',
//             opacity: 0.9,
//         },
//     },
//     '&:hover': {
//         opacity: 0.9,
//         background: 'none',
//     },
//     '& .MuiToggleButton-label': {
//         [theme.breakpoints.down('md')]: {
//             fontSize: '1em',
//         },
//     },
//     [theme.breakpoints.down('md')]: {
//         fontSize: '1em',
//     },
// }));

const variantButton: Record<ButtonVariantsLiteral, StyledComponent<ButtonProps & StyledProps>> = {
    primary: PrimaryButtonStyled,
    secondary: SecondaryButtonStyled,
    action: ActionButtonStyled,
    basic: BasicButtonStyled,
    accent: BasicButtonStyled,
};

export const BaseButton = ({ children, variant = 'basic', loading = false, TextComponentProps, ...rest }: IButtonProps) => {
    const TextComponent = ButtonText[variant];
    const ButtonComponent = variantButton[variant];
    return (
        <ButtonComponent {...rest} disabled={loading || rest.disabled}>
            {!loading && (
                <TextComponent color="inherit" {...TextComponentProps}>
                    {children}
                </TextComponent>
            )}
            {loading && (
                <TextComponent>
                    <CircularProgress size="12px" color="inherit" />
                </TextComponent>
            )}
        </ButtonComponent>
    );
};

// export const BaseButton = ({
//     children,
//     variant = 'basic',
//     className,
//     loading = false,
//     hoverBackground = false,
//     classNameOverride = false,
//     ...rest
// }: IButtonProps) => {
//     const classes = useStyles();
//     const TextComponent = ButtonText[variant];
//     return (
//         <Button
//             className={
//                 classNameOverride
//                     ? className
//                     : clsx([
//                           classes.base,
//                           classes[variant],
//                           {
//                               [classes[`${variant}Hover`]]: hoverBackground,
//                           },
//                           className,
//                       ])
//             }
//             {...rest}
//             disabled={loading || rest.disabled}
//         >
//             {!loading && <TextComponent color="inherit">{children}</TextComponent>}
//             {loading && (
//                 <TextComponent>
//                     <CircularProgress size="12px" color="inherit" />
//                 </TextComponent>
//             )}
//         </Button>
//     );
// };

const ButtonText = {
    basic: (props: ISizedTextProps) => <SmallText regular {...props} />,
    primary: (props: ISizedTextProps) => <MediumText semibold {...props} />,
    secondary: (props: ISizedTextProps) => <NormalText {...props} />,
    accent: (props: ISizedTextProps) => <Caption {...props} />,
    toggle: (props: ISizedTextProps) => <NormalText {...props} />,
    action: (props: ISizedTextProps) => <NormalText {...props} />,
};

const createButton = (props: IButtonProps, variant: ButtonVariantsLiteral) => BaseButton({ ...props, variant });

export const BasicButton = (props: IButtonProps) => createButton(props, 'basic');
export const PrimaryButton = (props: IButtonProps) => createButton(props, 'primary');
export const SecondaryButton = (props: IButtonProps) => createButton(props, 'secondary');
export const AccentButton = (props: IButtonProps) => createButton(props, 'accent');
export const ActionButton = (props: IButtonProps) => createButton(props, 'action');

export const ToggleButton = ({ children, icon, sx, ...rest }: IToggleButtonProps) => {
    const TextComponent = ButtonText.toggle;
    const theme = useTheme();
    return (
        <ToggleButtonLab
            sx={{
                textTransform: 'none',
                border: `1px solid ${theme.palette.grey[100]}`,
                borderRadius: '5px',
                color: '#555555',
                padding: theme.spacing(1, 2),
                outline: 'none!important',
                '&.Mui-selected': {
                    backgroundColor: theme.palette.secondary.main,
                    border: `1px solid ${theme.palette.secondary.main}`,
                    color: theme.palette.primary.main,
                    '&:hover': {
                        backgroundColor: theme.palette.secondary.main,
                        border: `1px solid ${theme.palette.secondary.main}`,
                        color: theme.palette.primary.main,
                        opacity: 0.9,
                    },
                },
                '&:hover': {
                    opacity: 0.9,
                },
                fontSize: '1em',
                '& .MuiToggleButton-label': {
                    fontSize: '1em',
                },
                ...sx,
            }}
            {...rest}
        >
            {icon || null}
            <TextComponent color="inherit" style={{ flex: 1 }}>
                {children}
            </TextComponent>
        </ToggleButtonLab>
    );
};
interface IButtonsCompoundProps extends Omit<ButtonProps, 'variant'> {
    children: ReactNode;
    variant?: ButtonVariantsLiteral;
    transparent?: boolean;
}

interface StyledCompoundProps extends HTMLAttributes<HTMLDivElement> {
    theme?: Theme;
}

const PrimaryCompoundStyled = styled(Box)(({ theme }: StyledCompoundProps) => ({
    textTransform: 'none',
    background: `linear-gradient(115.87deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.light} 100%)`,
    color: 'white',
    borderRadius: '50px',
    boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.15)',
    minWidth: 100,
    transition: theme.transitions.create(['transform', 'box-shadow'], {
        duration: theme.transitions.duration.short,
    }),
    '&:disabled': {
        background: theme.palette.grey[100],
        boxShadow: 'none',
    },
    '&:hover': {
        opacity: 1,
    },
    padding: 0,
    width: '100%',
    overflow: 'hidden',
}));

const SecondaryCompoundStyled = styled(Box)(({}: StyledCompoundProps) => ({
    textTransform: 'none',
    color: '#009de0',
    border: `1.15px solid #D8D8D8`,
    backgroundColor: '#FFFFFF',
    boxSizing: 'border-box',
    borderRadius: '10px',
    '&:hover': {
        opacity: 1,
    },
    padding: 0,
    width: '100%',
    overflow: 'hidden',
}));

const BasicCompoundStyled = styled(Box)(() => ({
    textTransform: 'none',
    textDecoration: 'underline',
    minWidth: 0,
    '&:hover': {
        opacity: 1,
    },
    padding: 0,
    width: '100%',
    overflow: 'hidden',
}));

const ActionCompoundStyled = styled(Box)(({ theme }: StyledCompoundProps) => ({
    textTransform: 'none',
    color: theme.palette.primary.main,
    boxSizing: 'border-box',
    background: theme.palette.secondary.main,
    borderRadius: 5,
    '&:disabled': {
        background: theme.palette.grey[100],
    },
    '&:hover': {
        opacity: 1,
    },
    padding: 0,
    width: '100%',
    overflow: 'hidden',
}));

const variantCompound: Record<ButtonVariantsLiteral, any> = {
    primary: PrimaryCompoundStyled,
    secondary: SecondaryCompoundStyled,
    action: ActionCompoundStyled,
    basic: BasicCompoundStyled,
    accent: BasicCompoundStyled,
};

export const ButtonsCompound = ({ children, variant = 'basic', transparent, style, ...rest }: IButtonsCompoundProps) => {
    const theme = useTheme();
    const CompoundComponent = variantCompound[variant];

    const childrenArray = addChildrenAttributes<IButtonProps>(
        children,
        Array(Children.toArray(children).length).fill({
            // className: classes.compoundInnerButton,
            sx: {
                borderStyle: 'none',
                textDecoration: 'none',
                // backgroundColor: 'transparent',
                width: '100%',
                borderRadius: 0,
                '&:not(:last-child)': {
                    borderRight: `1px solid ${theme.palette.grey[100]}`,
                },
                // '&:hover': {
                //     opacity: 1,
                //     ...(sx?.['&:hover'] || {}),
                // },
                boxShadow: 'none',
            },
            variant,
            ...rest,
        })
    );

    return (
        <CompoundComponent
            // className={clsx([classes.base, classes[variant], classes.compoundContainer])}
            display="flex"
            justifyContent="space-around"
            style={transparent ? { background: 'transparent', ...style } : style}
            // sx={sx}
        >
            {childrenArray}
        </CompoundComponent>
    );
};

// const useStyles = makeStyles(theme => ({
//     base: {
//         textTransform: 'none',
//         '&:hover': {
//             background: 'none',
//         },
//     },
//     basic: {
//         textDecoration: 'underline',
//         '&:hover': {
//             textDecoration: 'underline',
//         },
//         padding: 0,
//         minWidth: 0,
//     },
//     basicHover: {},
//     primary: {
//         background: `linear-gradient(115.87deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.light} 100%)`,
//         color: 'white',
//         borderRadius: '50px',
//         padding: theme.spacing(1, 3),
//         boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.15)',
//         minWidth: 100,
//         '&:hover': {
//             background: `linear-gradient(115.87deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.light} 100%)`,
//             opacity: 0.9,
//         },
//         transition: theme.transitions.create(['transform', 'box-shadow'], {
//             duration: theme.transitions.duration.short,
//         }),
//         '&:disabled': {
//             background: theme.palette.grey[100],
//             boxShadow: 'none',
//         },
//     },
//     primaryHover: {},
//     secondary: {
//         border: `1.15px solid #D8D8D8`,
//         padding: theme.spacing(1, 3),
//         background: '#FFFFFF',
//         boxSizing: 'border-box',
//         borderRadius: '10px',
//         // minWidth: 100,
//     },
//     secondaryHover: {
//         '&:hover': {
//             background: `#f0f0f0`,
//         },
//     },
//     accent: {},
//     toggle: {
//         border: `1px solid ${theme.palette.grey[100]}`,
//         borderRadius: '5px',
//         color: '#555555',
//         padding: theme.spacing(1, 2),
//         outline: 'none!important',
//         '&.Mui-selected': {
//             backgroundColor: theme.palette.secondary.main,
//             border: `1px solid ${theme.palette.secondary.main}`,
//             color: 'white',
//             '&:hover': {
//                 backgroundColor: theme.palette.secondary.main,
//                 border: `1px solid ${theme.palette.secondary.main}`,
//                 color: 'white',
//                 opacity: 0.9,
//             },
//         },
//         '&:hover': {
//             opacity: 0.9,
//         },
//         '& .MuiToggleButton-label': {
//             [theme.breakpoints.down('md')]: {
//                 fontSize: '1em',
//             },
//         },
//         [theme.breakpoints.down('md')]: {
//             fontSize: '1em',
//         },
//     },
//     compoundInnerButton: {
//         borderStyle: 'none',
//         textDecoration: 'none',
//         background: 'none',
//         width: '100%',
//         borderRadius: 0,
//         '&:not(:last-child)': {
//             borderRight: `1px solid ${theme.palette.grey[100]}`,
//         },
//         '&:hover': {
//             opacity: 1,
//         },
//         boxShadow: 'none',
//     },
//     compoundContainer: {
//         '&:hover': {
//             opacity: 1,
//         },
//         padding: 0,
//         width: '100%',
//         overflow: 'hidden',
//     },
//     action: {
//         color: 'white',
//         boxSizing: 'border-box',
//         background: theme.palette.secondary.main,
//         borderRadius: 5,
//         '&:hover': {
//             background: theme.palette.secondary.main,
//         },
//         // '&.MuiButton-root': {
//         //     borderRadius: '0px',
//         // },
//         '&:disabled': {
//             background: theme.palette.grey[100],
//         },
//     },
// }));
