import { Box, Button } from '@mui/material';
import { MediumText, NormalText } from 'components/atoms/Typography';
import useTranslation from 'next-translate/useTranslation';
import React, { useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';

const BrowserSuggestion = () => {
    const { t } = useTranslation('common');
    const [showSuggestion, setShowSuggestion] = useState<boolean>(true);
    return (
        <>
            {showSuggestion && (
                <Box
                    sx={{
                        position: 'fixed',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        p: 2,
                        backgroundColor: 'rgb(246, 246, 246)',
                        boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',
                        borderWidth: '0',
                        borderColor: '#3C4048',
                        borderStyle: 'solid',
                        textAlign: 'center',
                        flexDirection: 'column',
                        zIndex: 998,
                    }}
                >
                    <Box
                        sx={{
                            backgroundColor: 'rgb(246, 246, 246)',
                            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.5)',
                            width: 30,
                            height: 30,
                            position: 'absolute',
                            top: -15,
                            right: 0,
                            borderRadius: '15px',
                            alignItems: 'center',
                        }}
                    >
                        <Button size="large" onClick={() => setShowSuggestion(false)} sx={{ left: -8, top: -5 }}>
                            <CloseIcon sx={{ color: 'black', height: 25, width: 25 }} />
                        </Button>
                    </Box>
                    <Box pt={1}>
                        <MediumText>{t('standardBrowserSuggestion')}</MediumText>
                    </Box>
                    <Box mt={2}>
                        <InfoOutlinedIcon sx={{ color: 'primary.main', height: 30, width: 30 }} />
                    </Box>
                </Box>
            )}
        </>
    );
};

export default BrowserSuggestion;
