import { useRef, useState } from 'react';
import type { FC } from 'react';
import { Box, Button, IconButton, ListItemIcon, ListItemText, MenuItem, Popover, Typography } from '@mui/material';
import useTranslation from 'next-translate/useTranslation';
import Router from 'next/router';
import { LargeText, NormalText } from 'components/atoms/Typography';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import { useScrollerContext } from 'context/scroller-context';

const languageOptions = {
    en: {
        icon: '/icons/uk_flag.svg',
        label: 'English',
    },
    mk: {
        icon: '/icons/mk_flag.svg',
        label: 'Macedonian',
    },
};

const LanguagePopover = ({ transparent }: { transparent: boolean }) => {
    const anchorRef = useRef<HTMLButtonElement | null>(null);
    const [open, setOpen] = useState<boolean>(false);
    const { lang } = useTranslation('common');
    const { data } = useScrollerContext();

    const isHeaderTransparentZone = data.isHeaderTransparent;

    const handleOpen = (): void => {
        setOpen(true);
    };

    const handleClose = (): void => {
        setOpen(false);
    };

    const handleChangeLanguage = (language: string): void => {
        // setLanguage(language);
        setOpen(false);
        Router.push(
            {
                pathname: Router.pathname,
                query: Router.query,
            },
            Router.asPath,
            {
                locale: language,
            }
        );
    };

    const selectedOption = languageOptions[lang];

    return (
        <>
            {/* <IconButton onClick={handleOpen} ref={anchorRef}>
                <Box
                    sx={{
                        display: 'flex',
                        height: 20,
                        width: 20,
                        // "& img": {
                        //   width: '100%'
                        // },
                    }}
                >
                    <img alt={selectedOption.label} src={selectedOption.icon} width="20" height="20" />
                </Box>
            </IconButton> */}
            <Button onClick={handleOpen} ref={anchorRef}>
                <LargeText
                    color={transparent && isHeaderTransparentZone ? 'white' : 'black'}
                    sx={{
                        textDecoration: 'underline',
                    }}
                >
                    {lang === 'en' ? 'EN' : 'MK'}
                </LargeText>
            </Button>
            <Popover
                anchorEl={anchorRef.current}
                anchorOrigin={{
                    horizontal: 'center',
                    vertical: 'bottom',
                }}
                keepMounted
                onClose={handleClose}
                open={open}
                PaperProps={{
                    style: { width: 240 },
                }}
            >
                {Object.keys(languageOptions).map(language => (
                    <MenuItem onClick={() => handleChangeLanguage(language)} key={language}>
                        <ListItemIcon>
                            <Box
                                sx={{
                                    display: 'flex',
                                    height: 20,
                                    width: 20,
                                }}
                            >
                                <img
                                    alt={languageOptions[language].label}
                                    src={languageOptions[language].icon}
                                    height="20"
                                    width="20"
                                />
                            </Box>
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Typography color="textPrimary" variant="subtitle2">
                                    {languageOptions[language].label}
                                </Typography>
                            }
                        />
                    </MenuItem>
                ))}
            </Popover>
        </>
    );
};

export default LanguagePopover;
