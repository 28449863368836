import { Box, useMediaQuery, useTheme } from '@mui/material';
import deliveryTo from 'assets/svg/deliveryTo.svg';
import { useLocationContext } from 'context/current-location';
import { Region } from 'klikni-jadi-shared-stuff';
import { entries, isEqual } from 'lodash';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useState } from 'react';
import { collection } from 'services/db/firestoreApi';
import { GeocoderService } from 'services/mapService';
import { useRouting } from 'services/routing';
import { Headings, MediumText, NormalText } from './atoms/Typography';
import LandingSearch from './LandingSearch';
import ModalPopup from './molecules/ModalPopup';
import Lottie from 'react-lottie';
import * as animationData from 'assets/lottie/driverLottie.json';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/reducers';
import NewLandingSearch from './NewLandingSearch';
import { geocodeLocation } from 'services/location/geocodeLocation';
import { findRegionByLocation } from 'services/location/findRegionByLocation';
import { useRest } from 'hooks/useRest';
interface IDeliveryToProps {
    routerQuery?: any;
}

interface Address {
    fullAddress: string;
    latitude: number;
    longitude: number;
}

const DeliveryTo = ({ routerQuery }: IDeliveryToProps) => {
    const [open, setOpen] = useState<boolean>(false);
    const [currentAddress, setCurrentAddress] = useState<Address>(null);
    const [selectedAddress, setSelectedAddress] = useState<Address>(null);
    const geocoderService = new GeocoderService();
    const { t } = useTranslation('common');

    const [currentRegion, setCurrentRegion] = useState<string>(null);
    const routing = useRouting();
    const [locationDenied, setLocationDenied] = useState<boolean>(false);
    const { update, data: location } = useLocationContext();
    const user = useSelector((state: RootState) => state.currentUser);

    const theme = useTheme();
    const matched = useMediaQuery(theme.breakpoints.down('md'));
    const cart = useSelector((state: RootState) => state.userCart);
    const { carts } = useRest();

    useEffect(() => {
        const successCallback = position => {
            const { latitude, longitude } = position.coords;

            geocodeLocation(latitude, longitude).then(({ city, currentAddress }) => {
                setLocationDenied(false);
                setCurrentAddress(currentAddress);
                setCurrentRegion(city);
                update('location', { latitude, longitude, fullAddress: currentAddress.fullAddress });
            });
        };
        const errorCallback = e => {
            console.log(e);
            setLocationDenied(true);
        };
        if ((!location?.location && !user.data) || (!location?.location && user?.data && !routerQuery?.a)) {
            if (routerQuery?.nearby) {
                const arr = routerQuery?.nearby.split(',');
                const longitude = parseFloat(arr[1]);
                const latitude = parseFloat(arr[0]);

                // geocodeLocation(latitude, longitude).then(({ currentAddress }) => {
                //     findRegionByLocation(latitude, longitude).then(region => {
                //         update('__data__', {
                //             location: {
                //                 latitude,
                //                 longitude,
                //                 fullAddress: currentAddress.fullAddress,
                //             },
                //             region: region?.id || null,
                //         });
                //     });
                // });
                // findRegionByLocation(latitude, longitude).then(region => {
                //     update('__data__', {
                //         location: {
                //             latitude,
                //             longitude,
                //             fullAddress: region.title,
                //         },
                //         region: region?.id || null,
                //         locationType: 'city',
                //     });
                //     // if (user?.data) {
                //     //     const _addresses = entries(user.data.addresses);
                //     //     const regionAddresses = _addresses.filter(([, a]) => a.region === region.id);
                //     //     let userAddress = null;
                //     //     let id = null;
                //     //     if (regionAddresses.length > 0) {
                //     //         const primary = regionAddresses.find(([, a]) => a.isDefault);
                //     //         if (primary) {
                //     //             userAddress = primary[1];
                //     //             id = primary[0];
                //     //         } else {
                //     //             userAddress = regionAddresses[0];
                //     //         }
                //     //     }

                //     //     console.log('user address', userAddress);

                //     //     // if (userAddress) {
                //     //     //     const payload = {
                //     //     //         update: 'address',
                //     //     //         data: { customerId: cart?.data?.host?.id, addressId: id },
                //     //     //     };
                //     //     //     carts.patch(payload as any, cart?.data?.id);
                //     //     // }
                //     // }
                // });
            } else {
                navigator.geolocation.getCurrentPosition(successCallback, errorCallback, {
                    timeout: 5000,
                    enableHighAccuracy: true,
                });
            }
        }
    }, [location]);

    const getRegion = async (latitude, longitude) => {
        const regions = await collection<Region>('configuration/local/regions');
        let found;

        for (const region of regions) {
            if (
                region?.coordinates &&
                google.maps.geometry.poly.containsLocation(
                    { lat: latitude, lng: longitude },
                    new google.maps.Polygon({ paths: region.coordinates })
                )
            ) {
                found = region?.id;
            }
        }
        return found;
    };

    const handleAddressChangeAutocomplete = (t: {
        address: {
            fullAddress: string;
            latitude: number;
            longitude: number;
        };
    }) => {
        const { address } = t;
        if (address) {
            setSelectedAddress({ latitude: address.latitude, longitude: address.longitude, fullAddress: address.fullAddress });

            collection<Region>('configuration/local/regions').then(regions => {
                let found;
                for (const region of regions) {
                    if (
                        region?.coordinates &&
                        google.maps.geometry.poly.containsLocation(
                            { lat: address?.latitude, lng: address?.longitude },
                            new google.maps.Polygon({ paths: region.coordinates })
                        )
                    ) {
                        found = region?.id;
                    }
                }
                setOpen(false);
                update('__data__', {
                    location: {
                        latitude: address?.latitude,
                        longitude: address.longitude,
                        fullAddress: address.fullAddress,
                    },
                    region: found,
                    addressId: null,
                });
                routing.push({
                    // to: '/partners',
                    queries: {
                        ...(address?.latitude
                            ? {
                                  nearby: `${[address?.latitude, address?.longitude].join(',')}`,
                                  city: found,
                              }
                            : {}),
                    },
                });
            });
        }
    };

    const getCurrentLocation = (latitude, longitude, fullAddress, city) => {
        setCurrentAddress({ latitude, longitude, fullAddress });

        setCurrentRegion(city);
        setLocationDenied(false);
    };
    return (
        <Box>
            <Box
                sx={{ cursor: 'pointer' }}
                pl={{ xs: 0, md: 0 }}
                display="flex"
                alignItems="center"
                onClick={() => setOpen(true)}
            >
                <img
                    src={deliveryTo}
                    style={{
                        width: 35,
                    }}
                />
                <Box ml={2} lineHeight="10px" flex={1} minWidth={0}>
                    <Box>
                        <NormalText color="#202125a3">{t('deliveryTo')}</NormalText>
                    </Box>
                    <Box
                        sx={{
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            color: 'primary.main',
                        }}
                    >
                        <NormalText color="primary">{location.location?.fullAddress} </NormalText>
                    </Box>
                </Box>
            </Box>
            <ModalPopup minWidth="250px" maxWidth="700px" open={open} handleClose={() => setOpen(false)}>
                <Box px={{ xs: 5, md: 10 }} py={5} display="flex" flexDirection="column" maxWidth={{ xs: '350px', md: '700px' }}>
                    <Headings.H2 sx={{ mb: 2, mt: 7 }}>{t('add_new_address')}</Headings.H2>
                    <MediumText sx={{ mb: 4, color: 'gray' }}>{t('add_new_address_desc')}</MediumText>
                    <NewLandingSearch
                        address={{
                            fullAddress: selectedAddress?.fullAddress,
                            longitude: selectedAddress?.longitude,
                            latitude: selectedAddress?.latitude,
                        }}
                        onAddressChange={address => handleAddressChangeAutocomplete({ address })}
                        placeholder={t('choose_delivery_address')}
                        onSetCurrentLocation={() => {
                            handleAddressChangeAutocomplete({ address: currentAddress });
                        }}
                        onGetCurrentLocation={getCurrentLocation}
                        locationDenied={locationDenied}
                        paperWidth="100%"
                        onCloseModal={() => setOpen(false)}
                    />
                    <Lottie
                        options={{
                            loop: true,
                            autoplay: true,
                            animationData: animationData,
                            rendererSettings: {
                                preserveAspectRatio: 'xMidYMid slice',
                            },
                        }}
                        height={matched ? 200 : 400}
                        width={matched ? 200 : 400}
                    />
                </Box>
            </ModalPopup>
        </Box>
    );
};

export default DeliveryTo;
