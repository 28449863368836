import clsx from 'clsx';
import { Children, cloneElement, isValidElement, ReactNode } from 'react';

type ElementsType = Array<Exclude<ReactNode, boolean | null | undefined>>;

export const addChildrenAttributes = <T extends {}>(children: ReactNode, attributes: Partial<T>[]) => {
    const elements = Children.toArray(children);

    const attrKeys = attributes.map(a => Object.keys(a) as (keyof T)[]);

    return elements.reduce<ElementsType>((acc, child, index) => {
        if (isValidElement<T>(child) && attributes) {
            acc.push(
                cloneElement(
                    child,
                    attrKeys[index].reduce((finalAttributes, ak) => {
                        let value = attributes[index][ak];
                        if (ak === 'className') {
                            ((value as unknown) as string) = clsx([child.props[ak], attributes[index][ak]]);
                        } else if (ak === 'sx') {
                            value = { ...child.props[ak], ...attributes[index][ak] };
                        } else if (child.props[ak]) value = child.props[ak];

                        return { ...finalAttributes, [ak]: value };
                    }, {})
                )
            );
        }
        return acc;
    }, []);
};
